<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-12" v-for="item in files" :key="item.id">
                <div class="bg-light mt-2 p-2">
                    <p class="mb-1">{{$moment(item.CreateDate).format("DD-MM-YYYY HH:mm")}}</p>
                    <div class="files">
                        <a :href="file" target="_blank" class="file d-inline-block mr-2 mb-2 text-large" v-for="file in item.files" :key="file">
                            <img :src="file" v-if="isImage(file)" />
                            <span class="is-file" v-else><span class="fa fa-file"></span></span>
                            <span class="fa fa-file"></span> {{fileName(file)}}
                        </a>
                    </div>
                    <div v-html="item.text"></div>
                </div>
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({
        files: []
    }),
    components: { ...Editors },
    computed: {},
    methods: {
        getFiles() {
            this.$http.get("/api/module/suitsyou/production/" + this.entity.Id + "/images").then(x => {
                this.files = x.data;
            });
        },
        fileName(file) {
            var filename = file.replace(/^.*[\\\/]/, '')

            return filename;
        },
        isImage(file) {
            var ext = file.toLowerCase().split('.').pop();
            var imageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];

            return imageTypes.includes(ext);
        }
    },
    created() {
        this.getFiles();
    },
    mounted() {},
    mixins: [mixin]
};
</script>