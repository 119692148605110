import "./style.scss";

import Vue from 'vue'
const routes = [];


import ordersButtons from "./areas/ordersButtons";
Vue.component("smartWebOrdersButtons", ordersButtons);


import store from './store';

export { routes, store };
