<template>
	<b-card no-body v-if="entity && area">
		<div slot="header">
			<i :class="'fa ' + (area.icon ? area.icon : 'fa-cubes')"></i> {{area.name}}
		</div>

		<slot></slot>	

		<div class="card-tabs" v-if="tabs">
			<b-tabs card nav-wrapper-class="w-40">
				<b-tab v-for="tab in tabs" :key="tab.alias">
					<template slot="title">
						<i :class="'fa ' + (tab.icon ? tab.icon : 'fa-cube')"></i> {{tab.name}}
					</template>

					<component :is="tab.component" :entity="entity" :fields="getFields(tab.alias)" 
						:bus="bus" :tab="tabAlias" :alias="tab.alias" :other-module="module" :parent-tab="parentTab"></component>
					
					<component v-for="item in inlineAreas.filter(x => x.moduleArea == tab.alias)" :other-module="module" :parent-tab="parentTab"
						:is="item.component" :entity="entity" :fields="getFields(item.alias)" :bus="bus" :tab="tabAlias" :alias="item.alias" :key="item.alias"></component>
				</b-tab>
			</b-tabs>
		</div>

		<b-card-body v-if="custom">
			<div class="row">
				<div :class="fieldSize" v-for="field in area.customFields" :key="field.alias" v-show="field.type != 'hidden'">
					<component :is="'editor-' + field.type" :entity="entity" :access="field.access" :label="field.name" scope="module" 
						:alias="field.alias" :validate="false" :values="field.values" :other-module="module" :parent-tab="parentTab"></component>
				</div>
			</div>
		</b-card-body>

		<component v-else-if="area.component" :is="area.component" :entity="entity" :fields="getFields(area.alias)" 
			:bus="bus" :tab="tabAlias" :alias="area.alias" :other-module="module" :parent-tab="parentTab"></component>

		<component v-for="item in inlineAreas.filter(x => x.moduleArea == area.alias)" :other-module="module" :parent-tab="parentTab"
			:is="item.component" :entity="entity" :fields="getFields(item.alias)" :bus="bus" :tab="tabAlias" :alias="item.alias" :key="item.alias"></component>
	</b-card>
</template>

<script>
import hidden from './editors/hidden';
import text from './editors/text';
import readonly from './editors/readonly';
import number from './editors/number';
import richText from './editors/richText';
import select from './editors/select';
import selectMulti from './editors/selectMulti';
import date from './editors/date';
import user from './editors/user';
import role from './editors/role';

export default {
	data: () => ({
	}),
	inject: ['$validator'],
	components: { 
		'editor-hidden': hidden, 'editor-text': text, 'editor-rich-text': richText, 'editor-number': number, 
		'editor-readonly': readonly, 'editor-select': select, 'editor-selectMulti': selectMulti, 
		'editor-date': date, 'editor-date-range': date, 'editor-user': user, 'editor-role': role
	},
	props: {
		"alias": {
			default: null,
			type: String
		},
		"custom": Boolean,
		"bus": Object,
		"entity": Object,
		"tabAlias": String,
		"parentTab": String
	},
	computed: {
		area() {
			if (this.custom) {
				return this.module.customAreas.find(x => x.alias == this.alias);
			}
			return this.module.areas.find(x => x.alias == this.alias);
		},
		inlineAreas() {
			return this.module.areas.filter(x => x.areaType == "areaInline");
		},
		tabs() {
			var tabs = this.module.tabs.filter(x => x.moduleArea == this.alias);
			var externalTabs = this.module.externalTabs.filter(x => x.moduleArea == this.alias);

			var tabs = tabs.concat(externalTabs);
			if (tabs.length == 0) {
				return null;
			}
			return tabs;
		},
		fieldSize() {
			if (this.area.width < 6) {
				return "col-12";
			} else if (this.area.width < 9) {
				return "col-md-6";
			} else {
				return "col-md-4";
			}
		}
	},
	methods: {
		getFields(alias) {
			return this.module.fields.filter(x => x.areaId == alias);
		}
	},
	created() {		
	},
	mounted() {
	}
};
</script>