<template>
    <div class="related-contacts" v-if="entity.Id">
        <b-card-body>

            <div v-for="item in relations" :key="item.id" class="related-contact">
                <span class="fa fa-file-invoice fa-2x"></span> 
                <router-link :to="'/orders/' + item.id">
                    {{item.number}}
                </router-link>
                <span class="float-right">{{$moment(item.createDate).format("DD-MM-YYYY")}}</span>

                <div class="clearfix"></div>
            </div>
            
		</b-card-body>

        <b-card-footer class="text-right">
            <b-button @click="newOrder" size="sm" variant="secondary"><span class="fa fa-plus pr-1"></span> Opret ordre</b-button>
		</b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    mixins: [mixin],
    data: () => ({
        relations: []
    }),
    components: {},
    computed: {},
    watch: {
        "entity.Id"() {
            this.getRelations();
        }
    },
    methods: {
        getRelations() {            
            if (this.entity.Id) {
                this.$http.get("/api/module/orders/related-orders", {
                    params: {
                        id: this.entity.Id,
                        module: this.module.alias,
                    }
                }).then(x => {
                    this.relations = x.data;
                });
            }
        },
        newOrder() {
            this.$router.push({ 
                name: 'order', 
                params: { id: 'create', parentId: this.entity.Id, parentModule:  this.module.alias }
            });
        }
    },
    created() {
        this.getRelations();      
    },
    mounted() {}
};
</script>