<template>
	<div class="position-relative has-tool-menu" v-if="entity">
		<div class="tool-menu">
			<Breadcrumb :title="entity.name" />

			<div class="buttons">
				<ButtonAreas :entity="entity" />

				<b-button @click="exportOrder" variant="success"><span class="fa fa-file-download"></span> Export order</b-button>

				<!-- <b-button @click="cancel" variant="secondary">Fortryd ændringer</b-button>
				<b-button @click="submit" variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button> -->
			</div>
		</div>

		<b-card no-body>
			<b-card-body class="p-1">
				<div class="row">
					<div class="col-12 col-sm-6 input-title">
						<i class="fa fa-file-invoice"></i>
						<span>{{entity.number}}</span>
					</div>
				</div>
			</b-card-body>
		</b-card>

		<ModuleLoader v-if="entity" :entity="entity" :bus="bus"></ModuleLoader>
	</div>
</template>

<script>
import mixin from "../mixins/module";

export default {
	data: () => ({
		entity: null,
		authWindow: null
	}),
	components: {},
	watch: {
		id() {
			this.getEntity();
		}
	},
	computed: {
		creating() {
			return this.$route.params.id == "create";
		},
		id() {
			return this.$route.params.id;
		},
		owner()  {
			return this.$users.find(x => x.id == this.entity.owner);
		}
	},
	methods: {
		getEntity() {
			this.$http.get("/api/module/suitsyou/production/" + this.id).then(x => {
				this.entity = x.data;
				this.resetValidation();
			});
		},
		submit() {
		},
		cancel() {
			this.bus.$emit("cancel");
			this.getEntity();
		},
		exportOrder() {
			this.bus.$emit("exportOrder");
		}
	},
	created() {
		this.getEntity();

		if (this.bus) {
			this.bus.$on('reload', () => {
				this.getEntity()
			});
		}
	},
	mounted() {
	},
	mixins: [mixin]
};
</script>
