<template>
	<div class="button-areas">
		<component :entity="entity" :entities="entities" :is="item.component" v-for="item in buttonAreas" :key="item.alias"></component>
	</div>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	props: ["entity", "entities"],
	computed: {
		buttonAreas() {
			var areas = this.module.areas;
			if (areas) {
				return areas.filter(e => e.areaType == 'buttonArea');
			}
			return [];
		}
	},
	methods: {},
	created() {},
	mounted() {}
};
</script>