<template>
	<b-modal ref="dialogModal" :title="title" :ok-only="okOnly"
		:class="'dialog-modal modal-' + variant" :cancel-title="cancelTitle"
		v-model="dialogModal" :ok-variant="variant" :ok-title="okTitle"
		@ok="ok" @cancel="cancel" :size="size" :centered="centered" @hidden="hidden">
		
		<b-media>
			<i slot="aside" :class="'fa fa-exclamation-triangle text-' + variant"></i>
			<p class="mb-0" v-html="message"></p>
		</b-media>
    </b-modal>
</template>

<script>
export default {
	data: () => ({
		dialogModal: true
	}),
	components: {},
	computed: {},
	watch: {},
	props: [
		"title",
		"message",
		"variant",
		"okTitle",
		"cancelTitle",
		"okOnly",
		"size",
		"centered",
		"onConfirm",
		"onCancel"
	],
	methods: {
		ok() {			
			this.dialogModal = false;
			if (this.onConfirm) {
				this.onConfirm();
			}
		},
		cancel() {
			this.dialogModal = false;
			if (this.onCancel) {
				this.onCancel();
			}
		},
		hidden() {
			if (typeof this.$el.remove !== 'undefined') {
				this.$el.remove()
			} else if (typeof el.parentNode !== 'undefined') {
				this.$el.parentNode.removeChild(this.$el)
			}
		}
	},
	created() {},
	mounted() {},
	beforeMount() {
		// Insert the Dialog component in body tag
		this.$nextTick(() => {
			document.body.appendChild(this.$el)
		})
	}
};
</script>