import "./style.scss";

import Vue from 'vue'
const routes = [];

const RouterView = { render (c) { return c('router-view') } };

//import PricingProduct from "./areas/pricing";
//Vue.component("pricingProduct", PricingProduct);

import Doorcodes from "./doorcodes";

routes.push({ path: "doorcodes", component: RouterView, meta: { label: "Dørkoder" }, children: [
    { path: "", component: Doorcodes, name: "doorcodes", meta: { module: "doorcodes" } },
] });

export { routes };
