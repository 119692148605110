<template>
    <b-card-body class="">

        <b-row>
            <b-col>
                <b-card>
                    <template #header>
                        <div class="d-flex justify-content-between align-items-center">
                            Varianttyper
                        </div>
                    </template>

                    <div>
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Navn</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in types" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td v-html="item.title"></td>
                                    <td class="text-right">
                                        <b-button size="sm" variant="primary" @click="getVariantTypeValues(item)">Rediger</b-button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </b-card>

            </b-col>

            <b-col>
                <b-card v-if="values">
                    <template #header>
                        <div class="d-flex justify-content-between align-items-center">
                            <span v-html="type.title"></span>
                        </div>
                    </template>

                    <div>
                        <div class="d-flex align-items-center" style="gap: 10px">

                            <div>Opret intervaller: </div>

                            <div>
                                <b-form-input v-model="type.interval" type="number" placeholder="Interval gram"></b-form-input>
                            </div>

                            <div>
                                <b-form-input v-model="type.amount" type="number" placeholder="Antal"></b-form-input>
                            </div>

                            <b-button size="sm" variant="success" @click="createValues">Opret</b-button>

                        </div>

                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Navn</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in values" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td v-html="item.title"></td>
                                    <td class="text-right">
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </b-card>
            </b-col>

            <b-col>

            </b-col>
        </b-row>

    </b-card-body>
</template>

<script>
    import mixin from "../mixins/module";
    import Editors from "../mixins/editors";

    export default {
        data: () => ({
            types: [],
            type: null,
            values: null
        }),
        components: { ...Editors },
        computed: {
        },
        methods: {
            getVariantTypes() {
                this.$http.get("/api/module/pricing/variant-types/all").then(x => {{
                    this.types = x.data
                }})
            },
            getVariantTypeValues(item) {
                this.type = { ...item }

                this.$http.get("/api/module/pricing/variant-types/values?id=" + item.id).then(x => {{
                    this.values = x.data
                }})
            },
            createValues() {
                this.$http.post("/api/module/pricing/variant-types/values", this.type).then(x => {{
                    this.getVariantTypeValues(this.type)
                }})
            },

        },
        created() {
            this.getVariantTypes()
        },
        mounted() { },
        mixins: [mixin]
    };
</script>