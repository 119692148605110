<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" 
		v-slot:default="props" :scope="scope" 
		v-if="(f.access || access) > 0">
		<flat-pickr v-if="(f.access || access) >= 30"
                v-model="entity[f.alias || alias]"
                :config="config"        
                placeholder="Vælg dato"               
                :name="props.alias" 
				v-validate="validateRule"
				:data-vv-scope="props.scope"
				:data-vv-as="f.name || label"
				class="form-control">
        </flat-pickr>
		<b-input-group v-else>
			<b-form-input type="text" placeholder="Vælg dato" 
				:value="entity[(f.alias || alias)]" 
				:disabled="(f.access || access) < 30"></b-form-input>
		</b-input-group>
	</FormGroup>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {Danish} from 'flatpickr/dist/l10n/da.js';

export default {
	data: () => ({
		f: {},
		config:  {
          wrap: false,
          altFormat: 'd-m-Y',
          altInput: true,
          dateFormat: 'Y-m-d',
		  locale: Danish,
		  disableMobile: true,
		  mode: "single",
		  defaultDate: null,
		  //weekNumbers: true
        },
	}),
	inject: ['$validator'],
	components: { flatPickr },
	computed: {
		validateRule() {
			if (this.validate === true) {
				return "required";
			}

			if (this.validate === false || this.validate == null) {
				return null;
			}

			if (this.validate) {
				return this.validate;
			}
		}
	},
	watch: {
		// entityValue(val) {
		// 	this.$emit('input', val);
		// }
	},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		validate: [Boolean, String],
		access: {
			type: Number,
			default: 60
		},
		// value: String
	},
	methods: {},
	created() {
		if (this.item) {
			this.f = this.item;

			// this.$emit('input', this.entityValue);
		}
	},
	mounted() {}
};
</script>