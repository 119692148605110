<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<!-- <b-button variant="secondary">Fortryd ændringer</b-button> -->
				<b-button v-if="module.access >= 30" variant="success" @click="newContact"><span class="fa fa-plus"></span> Opret kontaktperson</b-button>
			</div>
		</div>

		<b-card no-body>
			<div slot="header">
				<i class="fa fa-building"></i> Kontaktpersoner
				<div class="card-header-actions">
					<!-- <b-link href="#" class="card-header-action btn-setting">
						<i class="fa fa-cog"></i>
					</b-link> -->
				</div>
			</div>
			<DataTable ref="dataTable" v-if="fields" :url="'/api/module/contacts'" :fields="fields" name="contacts" :filter="true" cache-search="contacts">
				<template slot="name" slot-scope="{props}">
					<b-link append :to="props.row.Id">
						{{props.row.name}}
					</b-link>
				</template>
				<template slot="owner" slot-scope="{props}">
						{{$user(props.row.owner).name}}
				</template>
				<template slot="CreateDate" slot-scope="{props}">
					{{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
				</template>
				<template slot="edit" slot-scope="{props}">
					<b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
				</template>
			</DataTable>
		</b-card>
	</div>
</template>

<script>
import newContact from "./modals/_newContact.vue";

export default {
	data: () => ({
		fields: null
	}),
	components: {},
	computed: {},
	methods: {
		newContact() {
			this.$dialog.open(newContact, { }, { title: "Ny kontaktperson", okTitle: "Opret" })
		}
	},
	created() {
		this.$http.get("/api/module/contacts/fields").then(x => {			
			this.fields = x.data;

			this.fields.push({
				name: "edit",
				visible: true,
				label: "",
				class: "text-right",
			});
		});
	},
	mounted() {}
};
</script>