<template>
    <b-card-body class="pb-0">
        <FormGroup label="Følgere" scope="module" label-cols-md="3">
            <OptifySelect :options="$users" v-model="entity.followers" :multiple="true" label="name" track-by="id" autocomplete="off"></OptifySelect>
        </FormGroup>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({}),
    components: {},
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>