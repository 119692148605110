<template>
	<div class="layout">
		<slot name="main"></slot>

		<div v-for="(row, rowKey) in layout" :key="'row-' + rowKey" :class="'row pt-2 ' + rowClass">
			<div v-for="(col, colKey) in row" :key="'col-' + colKey" :class="colClass + ' ' + colSize(col.width)">
				<slot :col="col"></slot>

				<div v-for="(childRow, childRowKey) in col.rows" :key="'row-' + childRowKey" :class="'row pt-2 ' + rowClass">
					<div v-for="(childCol, childColKey) in childRow" :key="'col-' + childColKey" :class="colClass + ' ' + colSize(childCol.width)">
						<slot :col="childCol"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({}),
	props: {
		"layout": Array,
		"rowClass": {
			type: String,
			default: ""
		},
		"colClass": {
			type: String,
			default: ""
		}
	},
	inject: ['$validator'],
	components: {},
	computed: {},
	methods: {
		colSize(size) {
			if (size < 6) {
				return "col-md-6 col-xl-" + size;
			} else {
				return "col-xl-" + size;
			}
		}
	},
	created() {},
	mounted() {}
};
</script>