import EditorDate from "../_ui/editors/date";
import EditorHidden from "../_ui/editors/hidden";
import EditorNumber from "../_ui/editors/number";
import EditorCalculation from "../_ui/editors/calculation";
import EditorReadonly from "../_ui/editors/readonly";
import EditorRichText from "../_ui/editors/richText";
import EditorSelect from "../_ui/editors/select";
import EditorSelectMulti from "../_ui/editors/selectMulti";
import EditorText from "../_ui/editors/text";
import EditorUser from "../_ui/editors/user";
import EditorRole from "../_ui/editors/role";

export default {
    'editor-hidden': EditorHidden, 
    'editor-text': EditorText, 
    'editor-rich-text': EditorRichText, 
    'editor-number': EditorNumber, 
    'editor-readonly': EditorReadonly, 
    'editor-select': EditorSelect, 
    'editor-selectMulti': EditorSelectMulti, 
    'editor-date': EditorDate, 
    'editor-date-range': EditorDate, 
    'editor-user': EditorUser, 
    'editor-calculation': EditorCalculation,
    'editor-role': EditorRole
}