<template>
    <div class="related-contacts">
        <b-card-body>

            <div v-for="item in relations" :key="item.id" class="related-contact">
                <span class="fa fa-user-circle fa-2x"></span> 
                <router-link :to="'/contacts/' + item.id">{{item.name}}</router-link>
                <button class="btn btn-light btn-sm float-right" type="button" @click="removeRelation(item.id)"><i class="fa fa-times"></i></button>
            </div>
            
		</b-card-body>

        <b-card-footer class="text-right">
            <b-button @click="newRelation" size="sm" variant="secondary"><span class="fa fa-plus pr-1"></span> Tilføj kontakt</b-button>
		</b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import newRelatedContact from "../modals/_newRelatedContact.vue";

export default {
    mixins: [mixin],
    data: () => ({
        relations: []
    }),
    components: {},
    computed: {},
    methods: {
        getRelations() {
            this.$http.get("/api/module/contacts/related-contacts", {
                params: {
                    organizationId: this.entity.Id
                }
            }).then(x => {
                this.relations = x.data;
            });
        },
        newRelation() {
            this.$dialog.open(newRelatedContact, { entity: this.entity }, { 
                title: "Tilføj kontaktperson", 
                okTitle: "Gem", 
                onClose: () => {
                    this.getRelations();
                }
            })
        },
        removeRelation(id) {
            this.$http.delete("/api/module/contacts/related-organization", {
                data: {
                    contactId: id,
                    organizationId: this.entity.Id
                }
            }).then(x => {
                this.getRelations();
            });
        }
    },
    created() {
        this.getRelations();
    },
    mounted() {
    }
};
</script>