<template>
    <b-card-body>
        <div class="row">
            <div class="col-md-6" v-for="item in widget" :key="item.alias">
                <EditorNumber :item="field(item.alias)" :entity="entity" scope="module" :validate="false" />
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({
        widget: []
    }),
    components: { ...Editors },
    computed: {},
    methods: {
        getWidgetFields() {
            this.$http.get("/api/module/suitsyou/widget/5d7ba5322dce1817fc348f10/fields").then(x => {
                this.widget = x.data;
            });
        }
    },
    created() {
        this.getWidgetFields();
    },
    mounted() {},
    mixins: [mixin]
};
</script>