<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-md-6">
                <EditorDate :item="field('65fb79e6cd239776f7faa407')" :entity="entity" scope="module" :validate="false" internal="Intern udløbsdato" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('65fb79a9cd239776f7faa3fe')" :entity="entity" scope="module" :validate="false" internal="Intern udløb" />
            </div>

            <div class="col-md-6">
                <EditorDate :item="field('5e2c6b1f329b745cdca400bb')" :entity="entity" scope="module" :validate="false" internal="Udløbsdato" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5e2c6b09329b745cdca400b6')" :entity="entity" scope="module" :validate="false" internal="Kølevare" />
            </div>
            <div class="col-md-6">
                <EditorNumber :item="field('5e78e08f2f21322de8a06365')" :entity="entity" scope="module" :validate="false" internal="Min. vægt" />
            </div>
            <div class="col-md-6">
                <EditorNumber :item="field('5e78e0a02f21322de8a06368')" :entity="entity" scope="module" :validate="false" internal="Max. vægt" />
            </div>
        </div>
    </b-card-body>
</template>

<script>
    import mixin from "../../mixins/area";
    import Editors from "../../mixins/editors";

    export default {
        data: () => ({}),
        components: { ...Editors },
        computed: {},
        methods: {},
        created() {
        },
        mounted() {


        },
        mixins: [mixin]
    };
</script>