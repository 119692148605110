<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img src="/img/avatars/user.png" class="img-avatar" />
      <span class="user-name d-sm-down-none">{{$store.state.user}}</span>
    </template>\
    <template slot="dropdown">
      <!-- <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item><i class="far fa-bell" /> Updates
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="far fa-envelope" /> Messages
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-tasks" /> Tasks
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="far fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item> -->
      <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Indstillinger</strong>
      </b-dropdown-header>
      <b-dropdown-item><i class="far fa-user" /> Profil</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-cogs" /> Indstillinger</b-dropdown-item>
      <!-- <b-dropdown-item><i class="far fa-credit-card" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="far fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item><i class="fa fa-shield-alt" /> Lock Account</b-dropdown-item> -->
      <b-dropdown-item href="/logout"><i class="fa fa-lock" /> Log ud</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return { itemsCount: 42 }
  }
}
</script>
