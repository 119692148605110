import "./style.scss";

import Vue from 'vue'
const routes = [];

import lagerProduct from "./areas/lagerProduct";
Vue.component("mrbeefLagerProduct", lagerProduct);

import Products from "./products";

routes.push({ path: "mrbeef-lager", component: Products, meta: { label: "Produkter", module: "mrbeef-lager" } });

import store from './store';

export { routes, store };
