var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative has-tool-menu"},[_c('div',{staticClass:"tool-menu"},[_c('Breadcrumb'),_c('div',{staticClass:"buttons"},[_c('ButtonAreas'),(_vm.module.access >= 30)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.newProduct}},[_c('span',{staticClass:"fa fa-plus"}),_vm._v(" Opret varegruppe")]):_vm._e()],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"fa fa-tags"}),_vm._v(" Varegrupper "),_c('div',{staticClass:"card-header-actions"})]),(_vm.fields)?_c('DataTable',{ref:"dataTable",attrs:{"url":"/api/module/products/productgroups","fields":_vm.fields,"name":"productgroups","filter":true},scopedSlots:_vm._u([{key:"5ce68ef5d6c2910bb09c58a5",fn:function(ref){
var props = ref.props;
return [_c('b-link',{attrs:{"append":"","to":props.row.Id}},[_vm._v(" "+_vm._s(props.row["5ce68ef5d6c2910bb09c58a5"])+" ")])]}},{key:"5ce68f0ad6c2910bb09c58a6",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.$user(props.row["5ce68f0ad6c2910bb09c58a6"]).name)+" ")]}},{key:"CreateDate",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm"))+" ")]}},{key:"edit",fn:function(ref){
var props = ref.props;
return [_c('b-button',{attrs:{"append":"","to":props.row.Id,"variant":"primary"}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" Se mere")])]}}],null,false,3536166458)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }