import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { routes } from './routes'

Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    linkActiveClass: "open",
    linkExactActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes
})


router.beforeEach(async (to, _, next) => {
    if (!store.state.init) {
        await store.dispatch("INIT_STATE")
     
    //    store.dispatch("GET_USERS", to)
        store.dispatch("GET_ROLES")
    }


    var isLoggedIn = store.state.loggedIn
    if (!isLoggedIn && to.name != "login")
        return next({ name: 'login' })


    return next()
})


const DEFAULT_TITLE = 'Mr Beef'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.label || DEFAULT_TITLE
    })
})

export default router