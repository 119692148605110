<template>
    <div>
        <ModuleLoader :other-module="contactModule" :entity="contact" v-if="contact && contactModule" :bus="bus" :parent-tab="alias"></ModuleLoader>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({
        contact: null,
        contactModule: null
    }),
    components: { ...Editors },
    computed: {},
    watch: {
        "entity.contactId"(val) {            
            if (val == null) {
                this.createId().then(x => {
                    this.entity.contactId = x;
                });
            } else {
                this.getContact();
            }
        },
        contact: { 
			handler(newVal, oldVal) {	
				if (oldVal != null && !this.changes && newVal != null) {
					this.bus.$emit("changes");	
                }
                               
                this.entity["5de4d0649663b9475842a5a5"] = newVal.name;
			},
			deep: true
		}
    },
    methods: {
        getContactModule() {
            this.$http.get("/api/module/contacts/contact").then(x => {
                this.contactModule = x.data;
            });
        },
        getContact() {
            if (this.entity.contactId) {
                this.$http.get("/api/module/contacts/contact/" + this.entity.contactId).then(x => {                   
                    if (x.data == null || x.data == "") {
                        this.contact = {
                            Id: this.entity.contactId
                        };
                        var currentUser = this.$store.state.user;
                        var user = this.$users.find(x => x.email == currentUser);
                        if (user) {
                            this.contact.owner = user.id;
                        }
                    } else {
                        this.contact = x.data;
                    }
                    
                    this.resetValidation();
                });
            } else {
                this.createId().then(x => {
                    this.entity.contactId = x;
                });
            }
        },
        async saveContact() {
			await this.$validator.validate("module.*").then(async $isValid => {
				if ($isValid) {
					await this.$http.post("/api/module/contacts/" +  this.contact.Id, this.contact).then(x => {
						if (x.data === false) {
							this.showErrorMsg();
						} else  {
							//this.bus.$emit("submit");
							//this.showSuccessMsg();
							this.getContact();	
						}
					});
				}
			});
		},
    },
    created() {
        this.getContactModule();
        this.getContact();

        if (this.bus) {
            //this.bus.$on('submit', this.saveContact);

            this.bus.actions.push({ 
                sort: 100, 
                action: this.saveContact
            });
        }
    },
    mounted() {},
    mixins: [mixin]
};
</script>