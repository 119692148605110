<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-md-6">
                <EditorText :item="field('5ddf940662bd2b52886123ff')" :entity="entity" scope="module" :validate="false" internal="CVR" />
            </div>
            <div class="col-md-6">
                <EditorText :item="field('5dd283ba65258f1a5c4eed9d')" :entity="entity" scope="module" :validate="false" internal="Ekstern Navn" />
            </div>
            <div class="col-md-6">
                <EditorText :item="field('5dd289a965258f1a5c4eedd5')" :entity="entity" scope="module" :validate="false" internal="Ekstern Telefon" />
            </div>
            <div class="col-md-6">
                <EditorText :item="field('5dd289bd65258f1a5c4eedd6')" :entity="entity" scope="module" :validate="false" internal="Ekstern E-mail" />
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({}),
    components: { ...Editors },
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>