<template>
    <div class="productspec-fields">
        <b-card-body>
            <b-table-simple striped responsive>
                <thead>
                    <tr>
                        <th></th>
                        <th>Navn</th>
                        <th>Type</th>
                        <th>Standard værdi</th>
                        <th></th>
                    </tr>
                </thead>
                <draggable :handle="'.sort-col'" tag="tbody" group="fields" ghost-class="ghost" v-model="specFields">
                    <tr v-for="(item, idx) in specFields" :key="item.id">
                        <td class="sort-col">
                            <i class="fa fa-sort"></i>
                        </td>
                        <td>
                            {{item["5d13b72af7cb1732200f1c5d"]}}
                        </td>
                        <td>
                            {{item["5d13b746f7cb1732200f1c5e"]}}
                        </td>
                        <td>
                            {{item["5d13b776f7cb1732200f1c5f"]}}
                        </td>
                        <td class="text-right">
                            <b-button
                                @click="fieldModal(item)"
                                variant="primary"
                            ><i class="fa fa-edit"></i></b-button>
                            <b-button
                                @click="removeField(idx)"
                                variant="danger"
                            ><i class="fa fa-trash"></i></b-button>
                        </td>
                    </tr>
                </draggable>
            </b-table-simple>

        </b-card-body>
        <b-card-footer class="text-right">
            <b-button
                @click="fieldModal()"
                size="sm"
                variant="secondary"
            ><span class="fa fa-plus pr-1"></span> Tilføj felt</b-button>
        </b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import TextEditor from "../../_ui/editors/text";
import fieldModal from "../modals/_specField";

export default {
    data: () => ({
      specFields: []
    }),
    components: { TextEditor },
    computed: {},
    methods: {
        getFields() {
            this.$http.get("/api/module/products/productspecs/" + this.entity.Id + "/fields").then(x => {
                if (x.data.fields) {
                    this.specFields = x.data.fields;
                }
            });
        },
        async fieldModal(item) {
            var field = Object.assign({}, item);
            var config = { title: "Rediger felt", okTitle: "Rediger" };
            if (field.id == null) {
                field.id = await this.createId();

                config = { title: "Opret felt", okTitle: "Opret" };
            }

            this.$dialog.open(fieldModal, { fields: this.specFields, field: field }, config)
        },
        submit() {
            this.$http.post("/api/module/products/productspecs/" + this.entity.Id + "/fields", { fields: this.specFields }).then(x => {
                if (x.data !== false) {
                    this.getFields();
                } else {
                    this.$parent.showErrorMsg("Felter blev ikke gemt!");
                }
            }).catch(error => {                
                this.$parent.showErrorMsg("Felter blev ikke gemt!");
            });
        },
        removeField(idx) {
            this.specFields.splice(idx, 1);
        }
    },
    created() {
        this.getFields();
    },
    mounted() {},
    mixins: [mixin]
};
</script>