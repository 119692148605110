import Vue from 'vue'

const routes = [];
const modules = {};

import * as organizations from "./views/organizations/module";
routes.push(...organizations.routes);
modules.organizations = organizations.store;

import * as contacts from "./views/contacts/module";
routes.push(...contacts.routes);
modules.contacts = contacts.store;

import * as products from "./views/products/module";
routes.push(...products.routes);
modules.products = products.store;

import * as orders from "./views/orders/module";
routes.push(...orders.routes);
modules.orders = orders.store;

import * as notes from "./views/notes/module";
routes.push(...notes.routes);
modules.notes = notes.store;

import * as files from "./views/files/module";
routes.push(...files.routes);
modules.files = files.store;

import * as suitsyou from "./views/suitsyou/module";
routes.push(...suitsyou.routes);
modules.suitsyou = suitsyou.store;

import * as smartWebOrders from "./views/smartWebOrders/module";
modules.smartWebOrders = smartWebOrders.store;

import * as mrbeefLager from "./views/mrbeefLager/module";
routes.push(...mrbeefLager.routes);
modules.mrbeefLager = mrbeefLager.store;

import * as pricing from "./views/pricing/module";
routes.push(...pricing.routes);
modules.pricing = pricing.store;

import * as doorcodes from "./views/doorcodes/module";
routes.push(...doorcodes.routes);

export { routes, modules };