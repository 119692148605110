<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" v-slot:default="props" :scope="scope" v-if="(f.access || access) > 0">
		<b-form-input ref="input" type="text" v-model="entity[props.alias]" :state="props.state" :data-vv-scope="props.scope" :disabled="(f.access || access) < 30"
			v-validate="validateRule" :name="props.alias" :data-vv-as="f.name || label" @change="change" autocomplete="off"></b-form-input>
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {
		input() {
			return this.$refs.input;
		},
		validateRule() {
			if (this.validate === true) {
				return "required";
			}

			if (this.validate === false || this.validate == null) {
				return null;
			}

			if (this.validate) {
				return this.validate;
			}
		}
	},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		validate: [Boolean, String],
		access: {
			type: Number,
			default: 60
		},
		default: String
	},
	methods: {
		change(e) {
			this.$emit("change", e);
		}
	},
	created() {
		if (this.item) {
			this.f = this.item;
		}

		var val = this.entity[this.f.alias || this.alias];
		if ((val == null || val == "") && this.default != null) {
			this.entity[this.f.alias || this.alias] = this.default;
		}
	},
	mounted() {}
};
</script>