<template>
	<div>
		<FormGroup label="Kontaktperson" alias="contact" label-cols-md="3" v-slot:default="props" scope="related-contact">
			<OptifySelect :options="contacts" v-model="entity.contactId" :multiple="false" :show-labels="false"
				@search="search" @select="select" label="text" track-by="id" :internal-search="false" autocomplete="off">
			</OptifySelect>

			<input type="hidden" v-validate="'required'" v-model="entity.contactId" data-vv-as="Kontakt"
				:class="{ 'is-invalid': !props.state }" name="contact" data-vv-scope="related-contact" />
		</FormGroup>
	</div>
</template>

<script>

export default {
	data: () => ({
		contacts: []
	}),
	inject: ['$validator'],
	components: {},
	computed: {},
	watch: {},
	props: ["entity"],
	methods: {
		search(query) {			
			this.$http.post("/api/multi-select/contacts", {
				search: query,
				value: this.entity.contactId,
				values: [],
				taggable: true,
				fields: ["name", "5d7b8ae52dce1817fc348cfc", "5d7b8ad22dce1817fc348cfb"]
			}).then(x => {
				this.contacts = x.data;
			});
		},
		select(item) {
			if (item.isNew === true) {
				this.$http.post("/api/multi-select/contacts/create", {
					name: item.text
				}).then(x => {
					this.entity.contactId = x.data.id;
					this.search();
				});
			}
		},
		ok() {
			this.$validator.validate("related-contact.*").then(valid => {
				if (valid) {
					this.$emit("close");
				}
			});
		},
		cancel() {
			this.$emit("close");
		}
	},
	created() {
		this.search();
	},
	mounted() {},
};
</script>