<template>
	<div>
		<TextEditor :entity="form" label="Navn" alias="name" :validate="true" scope="product"></TextEditor>

		<UserPicker :entity="form" label="Oprettet af" alias="owner" :values="$users" :validate="true" scope="product"></UserPicker>
	</div>
</template>

<script>
import UserPicker from "../../_ui/editors/user";
import TextEditor from "../../_ui/editors/text";

export default {
	data: () => ({
		form: {
			name: null
		}
	}),
	components: { UserPicker, TextEditor },
	computed: {},
	watch: {},
	props: [],
	methods: {
		ok() {
			this.$validator.validate("product.*").then(valid => {
				if (valid) {
					this.$http.put("/api/module/products", this.form).then(x => {
						this.$emit("close");

						this.$router.push("/products/" + x.data);
					});
				}
			})
		},
		cancel() {
			this.$emit("close");
		}
	},
	created() {},
	mounted() {},
};
</script>