var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative has-tool-menu"},[_c('div',{staticClass:"tool-menu"},[_c('Breadcrumb'),_c('div',{staticClass:"buttons"},[_c('ButtonAreas',{attrs:{"entities":_vm.selected}})],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"fa fa-user-tie"}),_vm._v(" Production "),_c('div',{staticClass:"card-header-actions"})]),(_vm.fields)?_c('DataTable',{ref:"dataTable",attrs:{"url":"/api/module/suitsyou/production","fields":_vm.fields,"name":"orders","filter":true,"cache-search":"production"},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"number",fn:function(ref){
var props = ref.props;
return [_c('b-link',{attrs:{"append":"","to":props.row.Id}},[_vm._v(" "+_vm._s(props.row.number)+" ")])]}},{key:"5d9635b3be27b755e8c203e2",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.$moment(props.row['5d9635b3be27b755e8c203e2']).format("DD-MM-YYYY"))+" ")]}},{key:"CreateDate",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm"))+" ")]}},{key:"edit",fn:function(ref){
var props = ref.props;
return [_c('b-button',{attrs:{"append":"","to":props.row.Id,"variant":"primary"}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" Se mere")])]}}],null,false,673176086)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }