import "./style.scss";

import Vue from 'vue'
const routes = [];

const RouterView = { render (c) { return c('router-view') } };

//import PricingProduct from "./areas/pricing";
//Vue.component("pricingProduct", PricingProduct);

import Pricing from "./pricing";
import PricingProduct from "./pricingProduct";
import VariantTypes from "./variantTypes";

routes.push({ path: "pricing", component: RouterView, meta: { label: "Prisstyring" }, children: [
    { path: "", component: Pricing, name: "pricing", meta: { module: "pricing" } },
    { path: "variant-types", component: VariantTypes, name: "variant-types", meta: { module: "pricing" } },
    { path: ":id", component: PricingProduct, name: "pricing-product", meta: { label: "Produkt", module: "pricing" } },
] });

import store from './store';

export { routes, store };
