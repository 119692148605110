<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" 
		v-slot:default="props" :scope="scope" 
		v-if="(f.access || access) > 0">
		<multiselect :options="f.values || values" :multiple="false"
			v-model="entity[props.alias]" :label="selectLabel" 
			:disabled="(f.access || access) < 30"
			:allow-empty="!validate" :name="props.alias" :track-by="props.selectValue"
			autocomplete="off" :show-labels="false" :searchable="searchable" @input="$emit('select', $event)"></multiselect>

		<input type="hidden" v-if="validate" v-model="entity[props.alias]" v-validate="validate ? 'required' : null" 
			:name="props.alias" :data-vv-as="f.name || label" :data-vv-scope="scope" />
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		validate: Boolean,
		values: Array,
		selectLabel: String,
		selectValue: String,
		access: {
			type: Number,
			default: 60
		},
		searchable: {
			type: Boolean,
			default: false
		}
	},
	methods: {
	},
	created() {
		if (this.item) {
			this.f = this.item;
		}
	},
	mounted() {
	}
};
</script>