import Vue from "vue";

export default {
	data: () => ({
		changes: false
	}),
	watch: {
		entity: { 
			handler(newVal, oldVal) {	
				if (oldVal != null && !this.changes && newVal != null) {
					if (window) {
						window.addEventListener('beforeunload', window.browserIntercept);
					}
					this.changes = true;
				}
			},
			deep: true
		}
	},
    components: {},
    computed: {
    },
    methods: {
		resetChanges(skipWait) {
			this.changes = true;

			window.removeEventListener("beforeunload", window.browserIntercept);

			if (!skipWait) {
				setTimeout(() => {
					this.changes = false;
				}, 300);
			} else {
				this.changes = false;
			}
		},
		changesMade() {
			if (window) {
				window.addEventListener('beforeunload', window.browserIntercept);
			}
			this.changes = true;
		}
	},
	created() {
        if (this.bus) {
            this.bus.$on("changes", () => {
                this.changesMade();
            });
        }
	},
	beforeRouteLeave (to, from, next) {
		if (this.changes) {
			if (confirm("Er du sikker på at du vil forlade siden uden at gemme dine ændringer?")) {
				window.removeEventListener("beforeunload", window.browserIntercept);
				next();
			}
		} else {
			next();
		}
	},
}