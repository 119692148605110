<template>
	<b-nav-item class="d-md-down-none" @click="newProduct">
		<i class="fa fa-building pr-2"></i> Opret produkt
	</b-nav-item>
</template>

<script>
import newProduct from "./modals/_newProduct.vue";

export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {
		newProduct() {
			this.$dialog.open(newProduct, { }, { title: "Opret produkt", okTitle: "Opret" })
		}
	},
	created() {},
	mounted() {}
};
</script>