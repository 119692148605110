<template>
	<multiselect 
		class="optify-select"
		ref="optifySelect"
		:id="id"
		:options="options" 
		:multiple="multiple"
		:value="selectedValue"
		:placeholder="placeholder"
		:label="label" 
		:track-by="trackBy"
		:searchable="searchable"
		:allow-empty="allowEmpty"
		:loading="loading"
		:taggable="taggable"
		:options-limit="optionsLimit"
		:max="max"
		:internal-search="internalSearch"
		:preserve-search="preserveSearch"
		:show-labels="showLabels"
		:custom-label="customLabel"
		:select-label="selectLabel"
		:disabled="disabled"
		@input="input"
		@select="select"
		@remove="remove"
		@search-change="search"
		@tag="tag">

		<template v-slot:option="props">
			<slot name="option" :option="props.option" v-if="$refs.optifySelect"
				:selected-label="$refs.optifySelect.selectedLabel"
				:deselect-label="$refs.optifySelect.deselectLabel"
				:select-label="$refs.optifySelect.selectLabel"></slot>
		</template>
	</multiselect>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {
		selectedValue() {
			if (this.multiple) {				
				if (this.trackBy) {
					return this.options.filter(x => this.value.includes(x[this.trackBy]));
				} else {
					return this.options.filter(x => this.value.includes(x));
				}
			} else {
				return this.options.find(x => x[this.trackBy] == this.value);
			}
		}
	},
	props: {
		id: [String, Number],
		label: String,
		options: Array,
		placeholder: String,
		multiple: Boolean,
		allowEmpty: {
			type: Boolean,
			default: true
		},
		loading: Boolean,
		taggable: Boolean,
		trackBy: String,
		searchable: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		selectLabel: String,
		showLabels : Boolean,
		customLabel: Function,
		internalSearch: Boolean,
		preserveSearch: Boolean,
		max: Number,
		optionsLimit: Number,
		value: {
			type: [String, Number, Object, Array],
			default() {
				if (this.multiple) {
					return [];
				}
				return null;
			}
		}
	},
	methods: {
		input(value, id) {
			if (this.multiple) {				
				this.$emit("input", value.map(x => x[this.trackBy]));
			} else {
				if (value) {
					this.$emit("input", value[this.trackBy]);
				} else {
					this.$emit("input", value);
				}
			}
		},
		select(item) {
			this.$emit("select", item, this.value);
		},
		remove(removedOption) {			
			this.$emit("remove", removedOption, this.value);
		},
		tag(searchQuery) {
			this.$emit("tag", searchQuery, this.value);
		},
		search(searchQuery) {
			this.$emit("search", searchQuery, this.value);
		}
	},
	created() {},
	mounted() {		
		this.$emit("init", this.value, this.value)
	}
};
</script>