<template>
	<b-nav-item class="d-md-down-none" to="/orders/create">
		<i class="fa fa-building pr-2"></i> Ny ordre
	</b-nav-item>
</template>

<script>
import newOrder from "./modals/_newOrder.vue";

export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {
		newOrder() {
			this.$dialog.open(newOrder, { }, { title: "Ny ordre", okTitle: "Opret" })
		}
	},
	created() {},
	mounted() {}
};
</script>