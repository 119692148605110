<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />

                <b-button variant="primary" :disabled="loading" @click="fetchProducts">
                    <span :class="{ fa: true, 'fa-download': !loading, 'fa-sync': loading, 'fa-spin': loading }"></span>
                    Hent produkter
                </b-button>
            </div>
        </div>

        <b-card no-body>
            <div slot="header">
                <i class="fa fa-user-tie"></i> Produkter
                <div class="card-header-actions"></div>
            </div>
            <DataTable ref="dataTable" v-if="fields" url="/api/module/mrbeef-lager/products" :fields="fields" name="products" :filter="true" @row-click="rowClick" cache-search="mrbeef-lager">
                <template slot="name" slot-scope="{props}">
                    <b-link :to="'/products/' + props.row.Id">
                        {{props.row.name}}
                    </b-link>
                </template>

                <template slot="5e2c6b1f329b745cdca400bb" slot-scope="{props}">
                    <span v-if="props.row['5e2c6b1f329b745cdca400bb'] && props.row['5e2c6b09329b745cdca400b6'] == 'Ja'">
                        {{$moment(props.row['5e2c6b1f329b745cdca400bb']).format("DD-MM-YYYY")}}
                    </span>
                </template>

                <template slot="65fb79e6cd239776f7faa407" slot-scope="{props}">
                    <span v-if="props.row['65fb79e6cd239776f7faa407'] && props.row['65fb79a9cd239776f7faa3fe'] == 'Ja'">
                        {{$moment(props.row['65fb79e6cd239776f7faa407']).format("DD-MM-YYYY")}}
                    </span>
                </template>

                <template slot="CreateDate" slot-scope="{props}">
                    {{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
                </template>

                <template slot="edit" slot-scope="{props}">
                    <b-button variant="success" @click.stop="stockIn(props.row)"><i class="fa fa-sign-in-alt"></i> Lager ind</b-button>
                    <b-button variant="danger" @click.stop="stockOut(props.row)"><i class="fa fa-sign-out-alt"></i> Lager ud</b-button>
                </template>
            </DataTable>
        </b-card>
    </div>
</template>

<script>
    import lagerScanning from './modals/lagerScanning';

    export default {
        data: () => ({
            fields: [
                {
                    "name": "productId",
                    "type": "readonly",
                    "label": "Produkt Id",
                    "value": null,
                    "filter": true,
                    "visible": true,
                    "sort": true,
                    "class": null,
                    "values": []
                },
                {
                    label: "Navn",
                    name: "name",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Navn 2",
                    name: "name2",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Producent",
                    name: "brand",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Lagerstatus",
                    name: "stock",
                    filter: false,
                    type: "number",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Udløbsdato",
                    name: "5e2c6b1f329b745cdca400bb",
                    filter: true,
                    type: "date",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Kølevare",
                    name: "5e2c6b09329b745cdca400b6",
                    filter: false,
                    type: "text",
                    sort: false,
                    class: null,
                    value: null,
                    visible: false
                },
                {
                    label: "Intern udløbsdato",
                    name: "65fb79e6cd239776f7faa407",
                    filter: true,
                    type: "date",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },

                {

                    "name": "type",
                    "type": "select",
                    "label": "Type",
                    "value": null,
                    "filter": true,
                    "visible": true,
                    "sort": false,
                    "class": null,
                    "values": [
                        "normal",
                        "packet",
                        "discontinued"
                    ]
                },
                {
                    label: "Created",
                    name: "CreateDate",
                    filter: true,
                    type: "date",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    name: "edit",
                    visible: true,
                    label: "",
                    class: "text-right",
                }
            ],
            selected: [],
            loading: false
        }),
        components: {},
        computed: {},
        methods: {
            rowClick(e) {
                if (e.length > 0) {
                    //this.selected.push(e[0].Id);
                }
            },
            fetchProducts() {
                this.loading = true;
                this.$http.get("/api/module/mrbeef-lager/sync-products").then(x => {

                }).finally(x => {
                    this.loading = false;
                });
            },
            stockIn(product) {
                this.$dialog.open(lagerScanning, {}, {
                    title: product.name + " " + product.brand,
                    hideFooter: true,
                    noClose: true,
                    onClose: () => {
                        this.$refs.dataTable.refresh();
                    },
                    props: { productId: product.Id, stockIn: true }
                })
            },
            stockOut(product) {
                this.$dialog.open(lagerScanning, {}, {
                    title: product.name + " " + product.brand,
                    hideFooter: true,
                    noClose: true,
                    onClose: () => {
                        this.$refs.dataTable.refresh();
                    },
                    props: { productId: product.Id, stockIn: false }
                })
            }
        },
        created() {
        },
        mounted() { }
    };
</script>