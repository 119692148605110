import "./style.scss";

import Vue from 'vue';
const routes = [];
//routes.push({ path: "organizations", redirect: "organizations/ting" });
const RouterView = { render (c) { return c('router-view') } };

import QuickActions from "./quickActions";
Vue.component("qa-contacts", QuickActions);

import details from "./areas/details";
Vue.component("contactDetails", details);

import relatedContacts from "./areas/relatedContacts";
Vue.component("relatedContacts", relatedContacts);

import relatedOrganizations from "./areas/relatedOrganizations";
Vue.component("relatedOrganizations", relatedOrganizations);

import Contacts from "./contacts";
import Contact from "./contact";

routes.push({ path: "contacts", component: RouterView, meta: { label: "Kontaktpersoner" }, children: [
    { path: "", component: Contacts, name: "contacts", meta: { module: "contacts" } },
    { path: ":id", component: Contact, name: "contact", meta: { label: "Kontaktperson", module: "contacts" } }
] });

import store from './store';

export { routes, store };
