import EditorDate from "../_ui/editors/date";
import EditorHidden from "../_ui/editors/hidden";
import EditorNumber from "../_ui/editors/number";
import EditorCalculation from "../_ui/editors/calculation";
import EditorReadonly from "../_ui/editors/readonly";
import EditorRichText from "../_ui/editors/richText";
import EditorSelect from "../_ui/editors/select";
import EditorSelectMulti from "../_ui/editors/selectMulti";
import EditorText from "../_ui/editors/text";
import EditorUser from "../_ui/editors/user";
import EditorRole from "../_ui/editors/role";

export default {
    EditorDate,
    EditorHidden,
    EditorNumber,
    EditorCalculation,
    EditorReadonly,
    EditorRichText,
    EditorSelect,
    EditorSelectMulti,
    EditorText,
    EditorUser,
    EditorRole
}