<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<b-button variant="success" to="/admin/modules/create"><span class="fa fa-plus"></span> Opret modul</b-button>
			</div>
		</div>

		<b-card no-body>
			<div slot="header">
				<i class="fa fa-building"></i> Moduler
				<div class="card-header-actions">
					<b-link href="#" class="card-header-action btn-setting">
						<i class="fa fa-cog"></i>
					</b-link>
				</div>
			</div>
			<DataTable ref="dataTable" url="/api/admin/modules" :fields="fields" name="modules" :filter="true">
				<template slot="edit" slot-scope="{props}">
					<b-button variant="primary" :to="'/admin/modules/' + props.row.Id"><span class="fa fa-pen"></span> Rediger</b-button>
					<b-button variant="danger" @click="deleteModule(props.row)"><span class="fa fa-trash"></span> Slet</b-button>
				</template>
			</DataTable>
		</b-card>


	</div>
</template>

<script>
export default {
	data: () => ({
		fields: [
			{
				name: "Name",
				type: "text",
				label: "Navn",
				filter: true,
				value: null,
				visible: true,
				class: "",
				sort: true
			},
			{
				name: "Alias",
				type: "text",
				label: "Alias",
				filter: false,
				value: null,
				visible: true,
				class: "",
				sort: false
			},
			{
				name: "edit",
				visible: true,
				label: "",
				class: "text-right",
				sort: false
			}
		]
	}),
	components: {},
	computed: {},
	methods: {
		deleteModule(item) {
			this.$dialog.confirm({ 
				title: "Sletter modul", 
				message: "Er du sikker på at du vil slette <b>" + item.Name + "</b>?", 
				variant: "danger", 
				onConfirm: () => {
					this.$http.post("/api/admin/modules/delete/" + item.Id).then(x => {
						this.$refs.dataTable.refresh();
					});
				} 
			});
		}
	},
	created() {},
	mounted() {}
};
</script>