<template>
    <b-card-body class="pt-0">

        <div class="production-products row" v-for="(item, lineKey) in orderLines" :key="'orderline-' + lineKey">

            <div class="col-12">
                <div class="row">
                    <div class="col-6 col-md-4 product">
                        <h3>{{item.product}}</h3>
                    </div>
                    <div class="col-6 col-md-4 product">
                        <span v-if="item.variant">
                            Variant: <strong>{{item.variant}}</strong>
                        </span>
                    </div>
                </div>
            </div>
<!-- 
            <template v-for="(spec, key) in item.specs">
                <div class="col-6 col-md-4 spec" :key="'spec-' + key" v-if="getSpec(item.productId, key)">
                    <span style="border: solid 1px #d6d6d6; display: block; padding: 2px 6px;">
                        <span>{{getSpec(item.productId, key)}}:</span> <br><strong v-if="spec" v-html="spec.replace('\n', '<br>')"></strong>
                    </span>
                </div>
            </template> -->
            
            <template v-for="(spec, key) in getItemSpecs(item.productId)">
                <div class="col-6 col-md-4 spec" :key="'spec-' + key" v-if="getSpec(spec, item)">
                    <span style="border: solid 1px #d6d6d6; display: block; padding: 2px 6px;">
                        <span>{{getSpec(spec, item).name}}:</span> <br><strong v-html="getSpec(spec, item).value.replace('\n', '<br>')"></strong>
                    </span>
                </div>
            </template>

            <div class="col-12">
                <h4>Measurements</h4>
            </div>
            
            <div class="col-6 col-md-4 measure" v-for="(meas, key) in getProductMeasurements(item.productId, item.variantId)" :key="'measure-' + key">
                <span style="border: solid 1px #d6d6d6; display: block; padding: 2px 6px;">
                    {{meas.name}}: <strong>{{meas.value}} {{meas.name.toLowerCase().indexOf("weight") > -1 ? "kg." : "cm."}}</strong>
                </span>
            </div>

        </div>

    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({
        specs: [],
        measurements: null,
        contactFields: [],
        additions: [],
    }),
    components: { ...Editors },
    computed: {
        orderLines() {
            return this.entity.orderLines.filter(x => x.brand && x.productId && this.toLower(x.brand) == this.toLower(this.entity['5d8a057dfa22d0204498933d']));
        }
    },
    methods: {
        toLower(value) {
            if (value) {
                return value.toLowerCase();
            }
            return null;
        },
        getSpecs() {
            this.$http.get("/api/module/suitsyou/production/specs/" + this.entity.Id).then(x => {
                this.specs = x.data;
            });
        },
        getMeasurements() {
            this.$http.get("/api/module/suitsyou/production/measurements/" + this.entity.contactId).then(x => {
                this.measurements = x.data;
            });
        },
        getFields() {
			this.$http.get("/api/module/suitsyou/measurements/fields").then(x => {
				this.contactFields = x.data;
			});
		},
        getAdditions() {
            var orderLines = this.orderLines.map(x => x.productId);

            this.$http.post("/api/module/suitsyou/production/measurement/addition/" + this.entity.contactId, orderLines).then(x => {
                this.additions = x.data;
            });
        },

        getSpec(spec, item) {

            if (spec["5d13b746f7cb1732200f1c5e"] != "calculation") {
                return {
                    name: spec["5d13b72af7cb1732200f1c5d"],
                    value: item.specs[spec.id] || "&nbsp;"
                }
            }

            return null;
        },
        getItemSpecs(id) {
            var spec = this.specs.find(x => x.productId == id);
            if (spec) {
                return spec.fields;
            }
            return [];
        },

        getProductMeasurements(productId, variantId) {
            var contactId = this.entity.contactId;

            var result = [];

            var baseAdditions = this.additions.filter(x => x.ContactId == null && x.ProductId == productId);

            var addition = this.additions.find(x => x.ContactId == contactId && x.ProductId == productId && x.VariantId == variantId);
            if (!addition) {
                addition = this.additions.find(x => x.ProductId == productId && x.VariantId == variantId);
                if (!addition) {
                    addition = this.additions.find(x => x.ContactId == null && x.ProductId == productId);
                    if (!addition) {
                        addition = this.additions.find(x => x.ProductId == productId);
                    }
                }
            }
            if (addition) {
                addition.Fields.forEach(element => {
                    if (this.measurements && this.measurements.hasOwnProperty(element.alias)) {
                        var measurement = this.measurements[element.alias];
                        var field = this.contactFields.find(x => x.alias == element.alias);
                        
                        var calcValue = 0;
                        if (element.value) {
                            calcValue = parseFloat(element.value)
                        }
                        var value = parseFloat(measurement);

                        if (element.calc == "add") {
                            value += calcValue;
                        } else if (element.calc == "subtract") {
                            value -= calcValue;
                        } else if (element.calc == "multiply") {
                            value *= calcValue;
                        }
                        
                        result.push({
                            name: element.name || field.name,
                            value: value
                        });
                    }
                });

                return result.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }

            return result;
        }
    },
    created() {

        // if (data.item.productId) {
        //     this.$http.get("/api/module/orders/productspec/" + data.item.productId).then(x => {
        //         this.$set(data.item, "_productSpec", x.data)
        //     });
        // }

        this.getSpecs();
        this.getFields();
        this.getMeasurements();
        this.getAdditions();

        if (this.bus) {
			this.bus.$on('exportOrder', () => {
                var data = {
                    orderId: this.entity.number,
                    contactId: this.entity.contactId,
                    customer: this.entity["5de4d0649663b9475842a5a5"],
                    shippingDeadline: this.entity["5d8a04bafa22d02044989339"],
                    usageDate: this.entity["5d8a04aefa22d02044989338"],
                    orderLines: this.orderLines,
                    specs: this.specs,
                    additions: this.additions,
                    measurements: this.measurements,
                    contactFields: this.contactFields
                };
                this.$http({
                    url: '/api/module/suitsyou/production/export',
                    data: data,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, data.orderId + ' Production.xlsx');
                });
			});
		}
    },
    mounted() {},
    mixins: [mixin]
};
</script>