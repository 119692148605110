<template>
    <div>
		<b-button variant="primary" :disabled="loading" @click="fetchOrders">
			<span :class="{ fa: true, 'fa-download': !loading, 'fa-sync': loading, 'fa-spin': loading }"></span>
			Hent ordrer
		</b-button>

		<b-dropdown right split @click="printOrderList" variant="dark">
			<template v-slot:button-content>
				<span class="fa fa-print"></span> Print plukliste
			</template>
			<b-dropdown-item :disabled="!entities || entities.length == 0" @click="printSelected(entities)">Print valgte</b-dropdown-item>
		</b-dropdown>
		
		<b-button variant="warning" @click="startScanning"><span class="fa fa-barcode"></span> Start scanning</b-button>
	</div>
</template>

<script>
import printOrders from "../modals/printOrders.vue";
import orderScanning from "../modals/orderScanning.vue";

export default {
	data: () => ({
		loading: false
	}),
	props: ["entity", "entities"],
	components: {},
	computed: {},
	methods: {
		fetchOrders() {
			this.loading = true;
			this.$http.get("/api/module/smartweborders/fetch").then(x => {
				this.loading = false;
			});
		},
		printOrderList() {
			this.$dialog.open(printOrders, {}, { title: "Print plukliste", okTitle: "Print" })
		},
		printSelected(selected) {
			var ids = selected.map(x => x.Id);
			this.$http({
				url: '/api/module/smartweborders/printselected',
				data: ids,
				method: 'POST',
				responseType: 'blob', // important
			}).then((response) => {
				this.$fileDownload(response.data, 'plukliste.pdf');

				this.$emit("ok");
			});
		},
		startScanning() {
			this.$dialog.open(orderScanning, {}, { title: "Scan ordre", okTitle: "Luk", okOnly: true })
		}
	},
	created() {},
	mounted() {}
};
</script>