import Vue from 'vue'
import store from "./store";
import router from "./router";

import Breadcrumb from "./views/_ui/breadcrumb";
Vue.component("Breadcrumb", Breadcrumb);

import DataTable from "./views/_ui/dataTable";
Vue.component("DataTable", DataTable);

import FormGroup from "./views/_ui/formGroup";
Vue.component("FormGroup", FormGroup);

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import Multiselect from 'vue-multiselect'
Vue.component('Multiselect', {
    mixins: [Multiselect],
    props: {
        selectLabel: {
            default: "Tryk for at vælge"
        },
        deselectLabel: {
            default: "Tryk for at fjerne"
        },
        selectedLabel: {
            default: "Valgt"
        },
        placeholder: { 
            default: "Vælg ..."
        },
        tagPlaceholder: {
            default: "Tryk for at oprette"
        }
    }
});

import OptifySelect from "./views/_ui/optifySelect";
Vue.component("OptifySelect", OptifySelect);


import VueGridLayout from 'vue-grid-layout';
Vue.component("grid-layout", VueGridLayout.GridLayout);
Vue.component("grid-item", VueGridLayout.GridItem);

import draggable from 'vuedraggable';
Vue.component("draggable", draggable);

import ModuleLoader from "./views/_ui/moduleLoader.vue";
Vue.component("ModuleLoader", ModuleLoader);

import ModuleArea from "./views/_ui/area.vue";
Vue.component("ModuleArea", ModuleArea);

import ButtonAreas from "./views/_ui/buttonAreas.vue";
Vue.component("ButtonAreas", ButtonAreas);

import VersionHistory from "./views/_ui/versionHistory.vue";
Vue.component("VersionHistory", VersionHistory);


import Dialog from './views/_ui/dialog'
import Modal from './views/_ui/modal'

function open(propsData, component) {
    const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
    const DialogComponent = vm.extend(component)
    return new DialogComponent({
        store,
        router,
        el: document.createElement('div'),
        propsData
    })
}

const DialogProgrammatic = {
    confirm(params) {
        const defaultParam = {
            cancelTitle: "Fortryd",
            centered: true,
            variant: "default"
        }
        const propsData = Object.assign(defaultParam, params)
        return open(propsData, Dialog)
    },
    open(component, params, settings) {
        const defaultParam = {
            cancelTitle: "Fortryd",
            centered: true,
            variant: "primary",
            component: component,
            props: params
        }

        const propsData = Object.assign(defaultParam, settings)
        return open(propsData, Modal)
    }
}

Vue.component("Dialog", Dialog);
Vue.prototype.$dialog = DialogProgrammatic;
