<template>
	<div>
		<TextEditor :entity="variant" label="Navn" alias="5ce2e8e046cd0030dcf950e0" :validate="true" scope="variant"></TextEditor>
		<TextEditor :entity="variant" label="Beskrivelse" alias="5ddd4c2980f5e241501bd58b" :validate="false" scope="variant"></TextEditor>
		<TextEditor :entity="variant" label="SKU" alias="5ce2e8ef46cd0030dcf950e1" :validate="false" scope="variant"></TextEditor>
		<TextEditor :entity="variant" label="GTIN" alias="5d1a4a50ce49d323d0b5eda1" :validate="false" scope="variant"></TextEditor>
		<NumberEditor :entity="variant" label="Pris" alias="5ce2e90346cd0030dcf950e3" :validate="false" scope="variant"></NumberEditor>
		<NumberEditor :entity="variant" label="Kostpris" alias="5ce2e91146cd0030dcf950e4" :validate="false" scope="variant"></NumberEditor>
		<NumberEditor :entity="variant" label="Lagerantal" alias="5d1a4a64ce49d323d0b5eda2" :validate="false" scope="variant"></NumberEditor>
		<NumberEditor :entity="variant" label="Vægt" alias="5dd7fa2b52b45f2c74a31575" :validate="false" scope="variant"></NumberEditor>
	</div>
</template>

<script>
import TextEditor from "../../_ui/editors/text";
import NumberEditor from "../../_ui/editors/number";

export default {
	data: () => ({
	}),
	components: { TextEditor, NumberEditor },
	computed: {},
	watch: {},
	props: ["variants", "variant"],
	methods: {
		ok() {
			this.$validator.validate("variant.*").then(valid => {
				if (valid) {
					var item = this.variants.findIndex(x => x.id == this.variant.id);
					if (item != -1) {
						this.variants.splice(item, 1, this.variant);
					} else {
						this.variants.push(this.variant);
					}

					this.$emit("close");
				}
			})
		},
		cancel() {
			this.$emit("close");
		}
	},
	created() {},
	mounted() {},
};
</script>