<template>
	<div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<b-button @click="upsertUser()" variant="success"><span class="fa fa-plus"></span> Opret bruger</b-button>
			</div>
		</div>
		
		<b-card no-body>
			<div slot="header">
				<i class="fa fa-users"></i> Brugere
				<div class="card-header-actions">
					<b-link href="#" class="card-header-action btn-setting">
						<i class="fa fa-cog"></i>
					</b-link>
				</div>
			</div>
			<b-collapse id="collapse-users" visible>
				<DataTable ref="dataTable" v-if="fields" url="/api/admin/users" :fields="fields" name="users" :filter="true">
					<template slot="CreatedOn" slot-scope="{props}">
						{{$moment(props.row.CreatedOn).format("DD-MM-YYYY HH:mm")}}
					</template>
					<template slot="edit" slot-scope="{props}">
						<b-button @click="upsertUser(props.row)" variant="primary">Rediger</b-button>
						<b-button @click="deleteUser(props.row)" variant="danger">Slet</b-button>
					</template>
				</DataTable>
			</b-collapse>
		</b-card>


		<b-modal id="userModal" cancel-title="Fortryd" :centered="true"
			ok-title="Gem" @ok="saveUser" @hidden="user = null">
			<template slot="modal-title">
				<span v-if="user && user.Id">Rediger bruger</span>
				<span v-else>Opret bruger</span>
			</template>
			<div v-if="user">
				<FormGroup label="Navn" alias="Name" label-cols-md="3">
					<template slot-scope="props">
						<b-form-input type="text" v-model="user[props.alias]" :state="props.state"
							v-validate="'required'" :name="props.alias" :data-vv-as="props.label"></b-form-input>
					</template>
				</FormGroup>
				<FormGroup label="E-mail" alias="Email" label-cols-md="3">
					<template slot-scope="props">
						<b-form-input type="text" v-model="user[props.alias]" :state="props.state"
							v-validate="'required'" :name="props.alias" :data-vv-as="props.label"></b-form-input>
					</template>
				</FormGroup>
				<FormGroup label="Adgangskode" label-cols-md="3">
					<b-form-input type="text" v-model="user.password"></b-form-input>
				</FormGroup>
			</div>
		</b-modal>
	</div>
</template>

<script>

export default {
	data: () => ({
		fields: null,
		user: null
	}),
	components: {},
	computed: {},
	methods: {
		upsertUser(user) {
			this.$root.$emit('bv::show::modal','userModal');
			if (user) {
				this.user = { ...user };
			} else {
				this.user = {
					Email: null,
					Name: null,
					Password: null
				};
			}
		},
		deleteUser(user) {
			if (confirm("Er du sikker på at du vil slette " + user.Email + "?")) {
				this.$http.post("/api/admin/users/delete", user).then(x => {
					this.$refs.dataTable.refresh();
				});
			}
		},
		saveUser(evt) {
			evt.preventDefault();

			this.$validator.validate().then($isValid => {
				if ($isValid) {
					this.$http.post("/api/admin/users/update", this.user).then(x => {
						this.$root.$emit('bv::hide::modal','userModal');
						this.$refs.dataTable.refresh();
					});
				}
			});
		}
	},
	created() {
		this.$http.get("/api/admin/users/fields").then(x => {			
			this.fields = x.data;

			this.fields.push({
				name: "edit",
				visible: true,
				label: "",
				class: "text-right"
			});
		});
	},
	mounted() {}
};
</script>