<template>
	<div class="position-relative has-tool-menu" v-if="entity">
		<div class="tool-menu">
			<Breadcrumb :title="entity.name" />

			<div class="buttons">
				<b-button @click="cancel" variant="secondary">Fortryd ændringer</b-button>
				<b-button @click="submit" variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button>
			</div>
		</div>

		<b-card no-body>
			<b-card-body class="p-1">
				<div class="row">
					<div class="col-12 col-sm-6 input-title">
						<i class="fa fa-user"></i>
						<b-form-input type="text" size="lg" v-model="entity.name" :state="$isValid('name', 'module')" 
							v-validate="'required'" name="name" data-vv-as="Navn" data-vv-scope="module"></b-form-input>
					</div>
					<div class="col-sm-6 text-right">
						<b-dropdown class="owner-dropdown" variant="white" right>
							<template slot="button-content">
								<span>
									<i class="fa fa-user-circle"></i>
									<span v-if="owner">
										<strong>{{owner.name}}</strong>
										<small>Oprettet af</small>
									</span>
									<span v-else>
										<strong>Ingen ejer</strong>
									</span>
								</span>
							</template>
							<b-dropdown-text>
								<FormGroup label="Vælg ejer">
									<b-form-select v-model="entity.owner" :options="$users.filter(x => x.access >= 30)" 
										text-field="name" value-field="id" class="my-2"></b-form-select>
								</FormGroup>
							</b-dropdown-text>
						</b-dropdown>

						<b-dropdown class="m-2 mx-3" variant="outline-dark" right no-caret>
							<template slot="button-content">
								<i class="fa fa-ellipsis-h"></i>
							</template>
							<b-dropdown-item @click="duplicateEntity">Duplikér kontaktperson</b-dropdown-item>
							<b-dropdown-item @click="deleteEntity">Slet kontaktperson</b-dropdown-item>
							<VersionHistory :id="entity.Id" collection="contacts" />
						</b-dropdown>
					</div>
				</div>
			</b-card-body>
		</b-card>

		<ModuleLoader v-if="entity" :entity="entity" :bus="bus"></ModuleLoader>

	</div>
</template>

<script>
import mixin from "../mixins/module";
import changeCheck from "../mixins/changeCheck";

export default {
	data: () => ({
		entity: null
	}),
	components: {},
	watch: {
		id() {
			this.getEntity();
		}
	},
	computed: {
		id() {
			return this.$route.params.id;
		},
		owner()  {
			return this.$users.find(x => x.id == this.entity.owner);
		}
	},
	methods: {
		getEntity() {
			this.$http.get("/api/module/contacts/contact/" + this.id).then(x => {
				this.entity = x.data;
				this.resetValidation();
				this.resetChanges();
			});
		},
		submit() {
			this.$validator.validate("module.*").then($isValid => {
				if ($isValid) {
					this.$http.post("/api/module/contacts/" +  this.id, this.entity).then(x => {
						if (x.data === false) {
							this.showErrorMsg();
						} else  {
							this.bus.$emit("submit");
							this.showSuccessMsg();
							this.getEntity();	
						}
					});
				}
			});
		},
		cancel() {
			this.bus.$emit("cancel");
			this.getEntity();
		},
		duplicateEntity() {
			if (confirm("Er du sikker på at du vil duplikere " + this.entity.name + "?")) {
				this.resetChanges(true);

				this.$http.post("/api/module/contacts/duplicate/" +  this.id).then(x => {
					if (x.data === false) {
						this.showErrorMsg();
					} else  {
						this.bus.$emit("duplicateEntity");
						this.showSuccessMsg();
						this.$router.push("/contacts/" + x.data);
					}
				});
			}
		},
		deleteEntity() {
			if (confirm("Er du sikker på at du vil slette " + this.entity.name + "?")) {
				this.resetChanges(true);
				
				this.$http.delete("/api/module/contacts/" +  this.id).then(x => {
					if (x.data === false) {
						this.showErrorMsg();
					} else  {
						this.bus.$emit("deleteEntity");
						this.showSuccessMsg();
						this.$router.replace("/contacts");
					}
				});
			}
		}
	},
	created() {
		this.getEntity();		
	},
	mounted() {
	},
	mixins: [mixin, changeCheck]
};
</script>
