<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <!-- <b-button variant="secondary">Fortryd ændringer</b-button>
                <b-button variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button> -->
            </div>
        </div>

        <b-row>
            <b-col>
                <b-card>
                    <template #header>
                        <div class="d-flex justify-content-between align-items-center">
                            Dørkoder

                            <b-button variant="success" size="sm" @click="addKey">
                                <span class="fa fa-plus"></span>
                            </b-button>
                        </div>
                    </template>

                    <b-card-body class="">

                        <div v-for="(item, idx) in keys" :key="'key-' + idx" class="d-flex mb-2" style="gap: 8px">
                            <b-form-input v-model="item.key" placeholder="Dørkode"></b-form-input>

                            <b-button variant="primary" size="sm" @click="saveKey(item)">
                                <span class="fa fa-check"></span>
                            </b-button>

                            <b-button variant="danger" size="sm" @click="deleteKey(item, idx)">
                                <span class="fa fa-times"></span>
                            </b-button>
                        </div>

                    </b-card-body>
                </b-card>

            </b-col>

            <b-col>

            </b-col>

            <b-col>

            </b-col>
        </b-row>

    </div>
</template>

<script>
    export default {
        data: () => ({
            keys: [],
            settings: null
        }),
        components: {},
        computed: {},
        methods: {


            getKeys() {
                this.$http.get("/api/doorcodes/keys").then(x => {
                    this.keys = x.data
                })
            },
            addKey() {
                this.keys.splice(0, 0, {})
            },
            saveKey(key) {
                this.$http.post("/api/doorcodes/keys", key).then(x => {
                    this.getKeys()
                })
            },
            deleteKey(key, idx) {
                if (key.id) {
                    this.$http.delete("/api/doorcodes/keys/" + key.id).then(x => {
                        this.getKeys()
                    })
                } else {
                    this.keys.splice(idx, 1)
                }

            },
        },
        created() {

            this.getKeys()

        },
        mounted() { }
    };
</script>