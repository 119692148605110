<template>
	<b-card no-body v-if="area" class="pb-2">
		<div slot="header" v-b-toggle="area.alias">
			{{area.name}}
			<slot name="access" :area="area"></slot>

			<div class="card-header-actions" v-if="colArea.isCustom">
				<b-link @click="deleteArea" class="card-header-action btn-setting">
					<i class="fa fa-trash"></i>
				</b-link>
				<b-link @click="editArea" class="card-header-action btn-setting">
					<i class="fa fa-pen"></i>
				</b-link>
			</div>
		</div>

		<b-collapse :id="area.alias">
			<div class="card-tabs" v-if="tabs.length > 0">
				<b-tabs card nav-wrapper-class="w-40">
					<b-tab class="p-0" v-for="tab in tabs" :key="tab.alias">
						<template slot="title">
							{{tab.name}}
							
							<slot name="access" :tab="tab"></slot>
						</template>

						<div class="table-responsive">
							<table class="table table-striped table-md m-0">
								<tr>
									<th>Felt</th>
									<th>Type</th>
									<slot name="roles"></slot>
								</tr>
								<tr v-for="(item, key) in fields.filter(x => x.areaId == tab.alias)" :key="item.alias + '-' + key">
									<td>{{item.name}}</td>
									<td>{{item.type}}</td>
									<slot :item="item"></slot>
								</tr>
								<tr v-for="(item, key) in inlineFields.filter(x => x.moduleAlias == tab.alias)" :key="item.alias + '-' + key">
									<td>{{item.name}}</td>
									<td>{{item.type}}</td>
									<slot :item="item"></slot>
								</tr>
							</table>
						</div>
					</b-tab>
				</b-tabs>
			</div>
			<div class="table-responsive">
				<table class="table table-striped table-md m-0">
					<thead>
						<tr>
							<th v-if="colArea.isCustom"></th>
							<th>Felt</th>
							<th>Type</th>
							<th v-if="colArea.isCustom"></th>
							<slot name="roles"></slot>
						</tr>
					</thead>
					<draggable v-model="area.customFields" :handle="'.sort-col'" tag="tbody"
						group="fields" ghost-class="ghost" :disabled="!colArea.isCustom">
						<tr v-for="(item, key) in fields.filter(x => colArea.isCustom || (!colArea.isCustom && x.areaId == area.alias))" :key="item.alias + '-' + key">
							<td class="sort-col" v-if="colArea.isCustom">
								<i class="fa fa-sort"></i>
							</td>
							<td>{{item.name}}</td>
							<td>{{item.type}}</td>
							<td v-if="colArea.isCustom">
								<b-button @click="editField(item)" size="sm" variant="primary"><i class="fa fa-edit"></i></b-button>
								<b-button @click="removeField(key)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
							</td>
							<slot :item="item"></slot>
						</tr>
						<tr v-for="(item, key) in inlineFields.filter(x => x.moduleAlias == area.alias)" :key="item.alias + '-' + key">
							<td>{{item.name}}</td>
							<td>{{item.type}}</td>
							<slot :item="item"></slot>
						</tr>
					</draggable>
				</table>
				<div class="add-field" v-if="colArea.isCustom">
					<slot name="field" :area="area"></slot>
				</div>
			</div>
		</b-collapse>
	</b-card>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	props: {
		"colArea": Object,
		"moduleId": String,
		"menuId": String,
		"modules": Array,
		"clientAreas": Array
	},
	computed: {
		area() {	
			var area = this.areas.find(x => x.menuId == this.menuId && x.alias == this.colArea.areaAlias);
			if (area) {
				return area;
			}
			return this.clientAreas.find(x => x.menuId == this.menuId && x.alias == this.colArea.areaAlias)
		},
		areas() {
			return this.modules.flatMap(x => x.areas).filter(x => x.menuId == this.menuId && (x.areaType == "area"));
		},
		inlineAreas() {
			return this.modules.flatMap(x => x.areas).filter(x => x.menuId == this.menuId && (x.areaType == "areaInline"));
		},
		tabs() {
			return this.modules.flatMap(x => x.areas).filter(x => x.menuId == this.menuId && x.areaType == "tab" && x.moduleArea == this.area.alias);
		},
		fields() {
			if (this.colArea.isCustom) {
				return this.area.customFields.map(x => {					
					return {
						...x,
						moduleAlias: this.area.moduleId
					}
				});
			}
			
			return this.modules.flatMap(x => x.fields.map(f => {
				return {
					...f,
					moduleAlias: f.moduleAlias || x.id
				}
			})).filter(x => x.areaId == this.area.alias || this.tabs.find(t => t.alias == x.areaId));
		},
		inlineFields() {
			return this.modules.filter(x => x.areas.find(e => e.areaType == "areaInline")).flatMap(x => 
				x.fields.filter(e => this.inlineAreas.find(a => a.alias == e.areaId)).map(f => {
					var inlineArea = this.inlineAreas.find(a => a.alias == f.areaId);

					return {
						...f,
						moduleAlias: f.moduleAlias || inlineArea.moduleArea
					}
				})
			);
		}
	},
	methods: {
		editArea() {
			this.$emit("edit", { menuId: this.menuId, alias: this.area.alias });
		},
		deleteArea() {
			this.$emit("delete", { menuId: this.menuId, alias: this.area.alias });
		},
		editField(item) {
			this.$emit("editField", this.area, item);
		},
		removeField(item) {
			this.area.customFields.splice(item, 1);
		}
	},
	created() {		
	},
	mounted() {
	}
};
</script>