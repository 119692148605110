<template>
  <b-card-body>

		<TextEditor :entity="entity" label="Navn" alias="5ce68ef5d6c2910bb09c58a5" :validate="true" scope="module"></TextEditor>

		<TextEditor :entity="entity" label="Nummer" alias="5ce693e7d6c2910bb09c58d8" :validate="false" scope="module"></TextEditor>

  </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import TextEditor from "../../_ui/editors/text";

export default {
    data: () => ({}),
    components: { TextEditor },
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>