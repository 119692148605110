<template>
	<b-nav-item class="d-md-down-none" @click="newContact">
		<i class="fa fa-user-plus pr-2"></i> Ny kontakt
	</b-nav-item>
</template>

<script>
import newContact from "./modals/_newContact.vue";

export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {
		newContact() {
			this.$dialog.open(newContact, { }, { title: "Ny kontaktperson", okTitle: "Opret" })
		}
	},
	created() {},
	mounted() {}
};
</script>