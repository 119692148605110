<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-12">
                <b-form-group label="Status" label-cols-sm="2" label-cols-md="2" class="mt-2" label-class="px-1 py-2 font-weight-bold">
                    <b-form-radio-group v-model="status" size="md" buttons button-variant="secondary" internal="Status">
                        <b-radio v-for="item in statuses" :key="item.value" :value="item.value" :button-variant="item.buttonVariant" @change="updateOrder">
                            {{item.value}}
                        </b-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="col-6">
                <b-form-group label="Shipping deadline" label-cols="6" label-cols-sm="3" class="mt-2" label-class="px-1 py-2 font-weight-bold">
                    <div class="py-2">
                        {{$moment(entity['5d8a04bafa22d02044989339']).format("DD-MM-YYYY")}}
                    </div>
                </b-form-group>
            </div>
            
            <div class="col-6">
                <b-form-group label="Gender" label-cols="6" label-cols-sm="3" class="mt-2" label-class="px-1 py-2 font-weight-bold">
                    <div class="py-2">
                        {{entity['5d7b8ab52dce1817fc348cfa']}}
                    </div>
                </b-form-group>
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({
        stacked: false,
        statuses: [
            {
                value: "Production started",
                buttonVariant: "outline-warning"
            },
            {
                value: "Sent to Denmark",
                buttonVariant: "outline-success"
            },
            {
                value: "Cancelled",
                buttonVariant: "outline-danger"
            },
            {
                value: "Production defect",
                buttonVariant: "outline-danger"
            },
            // {
            //     value: "Deadline exceeded",
            //     buttonVariant: "outline-danger"
            // }
        ],
        status: null
    }),
    components: { ...Editors },
    computed: {},
    methods: {
        updateOrder(status) {            
            this.$http.post("/api/module/suitsyou/production/" + this.entity.Id, { status: status }).then(x => {
                
                this.bus.$emit("reload");
            });
        }
    },
    created() {
        this.status = this.entity["5df776fb665eb71e687bf338"];
    },
    mounted() {},
    mixins: [mixin]
};
</script>