<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<ButtonAreas :entities="selected" />
			</div>
		</div>

		<b-card no-body>
			<div slot="header">
				<i class="fa fa-user-tie"></i> Production
				<div class="card-header-actions">
					<!-- <b-link href="#" class="card-header-action btn-setting">
						<i class="fa fa-cog"></i>
					</b-link> -->
				</div>
			</div>
			<DataTable ref="dataTable" v-if="fields" url="/api/module/suitsyou/production" :fields="fields" name="orders" :filter="true" @row-click="rowClick" cache-search="production">
				<template slot="number" slot-scope="{props}">
					<b-link append :to="props.row.Id">
						{{props.row.number}}
					</b-link>
				</template>
				<template slot="5d9635b3be27b755e8c203e2" slot-scope="{props}">
					{{$moment(props.row['5d9635b3be27b755e8c203e2']).format("DD-MM-YYYY")}}
				</template>
				<template slot="CreateDate" slot-scope="{props}">
					{{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
				</template>
				<template slot="edit" slot-scope="{props}">
					<b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
				</template>
			</DataTable>
		</b-card>
	</div>
</template>

<script>
export default {
	data: () => ({
		fields: [
			{
				label: "Order",
				name: "number",
				filter: true,
				type: "text",
				sort: true,
				class: null,
				value: null,
				visible: true
			},
			{
				label: "Status",
				name: "5df776fb665eb71e687bf338",
				filter: true,
				type: "select",
				visible: true,
				values: [
					"New order",
					"Production started",
					"Sent to Denmark",
					"Cancelled",
					"Production defect"
				]
			},
			{
				label: "Shipping deadline",
				name: "5d8a04bafa22d02044989339",
				filter: false,
				type: "date",
				visible: true
			},
			{
				label: "Producer",
				name: "5d8a057dfa22d0204498933d",
				filter: true,
				type: "text",
				visible: true
			},
			{
				label: "Created",
				name: "CreateDate",
				filter: true,
				type: "date",
				sort: true,
				class: null,
				value: null,
				visible: true
			},
			{
				name: "edit",
				visible: true,
				label: "",
				class: "text-right",
			}
		],
		selected: []
	}),
	components: {},
	computed: {},
	methods: {
		rowClick(e) {
			if (e.length > 0) {
				this.$router.push({ path: "/production/" + e[0].Id });
			}
		}
	},
	created() {
	},
	mounted() {}
};
</script>