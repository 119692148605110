<template>
	<div class="input-group">
		<select :placeholder="item.label" v-model="item.value" @input="refresh" class="custom-select">
			<option :value="null">{{item.label}}</option>
			<option v-for="user in $users" :key="user.id" :value="user.id">{{user.name}}</option>
		</select>
		<div class="input-group-append">
			<b-button variant="secondary" @click="clear"><i class="fa fa-times"></i></b-button>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {},
	props: ["item"],
	methods: {
		refresh() {
			this.$emit("refresh");
		},
		clear() {
			this.item.value = null;
			this.refresh();
		}
	},
	created() {},
	mounted() {}
};
</script>