<template>
    <div class="product-variants">
        <b-card-body>
            <b-table striped responsive :items="entity.variants" :fields="columns">

                <template v-slot:cell(edit)="data">
                    <b-button @click="variantModal(data.item)" variant="primary"><i class="fa fa-edit"></i></b-button>
                    <b-button @click="removeVariant(data.index)" variant="danger"><i class="fa fa-trash"></i></b-button>
                </template>

            </b-table>
        </b-card-body>
        <b-card-footer class="text-right">
            <b-button @click="variantModal()" size="sm" variant="secondary"><span class="fa fa-plus pr-1"></span> Tilføj variant</b-button>
		</b-card-footer>   
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import variantModal from "../modals/_variant";

export default {
    data: () => ({
        columns: [
            { label: 'Navn', key: '5ce2e8e046cd0030dcf950e0', sortable: false, class: '' },
            { label: 'SKU', key: '5ce2e8ef46cd0030dcf950e1', sortable: false, class: '' },
            { label: 'GTIN', key: '5d1a4a50ce49d323d0b5eda1', sortable: false, class: '' },
            { label: 'Pris', key: '5ce2e90346cd0030dcf950e3', sortable: false, class: '' },
            { label: 'Kostpris', key: '5ce2e91146cd0030dcf950e4', sortable: false, class: '' },
            { label: 'Lagerantal', key: '5d1a4a64ce49d323d0b5eda2', sortable: false, class: '' },
            { label: 'Vægt', key: '5dd7fa2b52b45f2c74a31575', sortable: false, class: '' },
            { label: '', key: 'edit', sortable: false, class: 'text-right' }
        ]
    }),
    components: {},
    computed: {},
    methods: {
        async variantModal(item) {
            var variant = Object.assign({}, item);
            var config = { title: "Rediger variant", okTitle: "Rediger" };
            if (variant.id == null) {
                variant.id = await this.createId();

                config = { title: "Opret variant", okTitle: "Opret" };
            }

            this.$dialog.open(variantModal, { variants: this.entity.variants, variant: variant }, config)
        },
        removeVariant(idx) {
            this.entity.variants.splice(idx, 1);
        }
    },
    created() {
    },
    mounted() {
    },
    mixins: [mixin]
};
</script>