<template>
	<b-tabs nav-wrapper-class="w-40" class="mb-2 tabs-default" v-bind:class="{ singleTab: mainTabs.length <= 1 }">
		<b-tab v-for="(tab, key) in mainTabs" :key="'tab-' + key" :title-link-class="hasModuleError(tab) ? 'text-danger' : ''">
			<template slot="title">
				<i class="fa fa-cube"></i> {{tab.name}}
				<i v-if="hasModuleError(tab)" class="ml-2 text-danger fa fa-exclamation-triangle"></i>
			</template>

			<Layout :layout="getLayout(tab.alias)">
				<template v-slot:main>
					<component :is="tab.component" class="pt-2" :other-module="module" :entity="entity" :bus="bus" :alias="tab.alias" :parent-tab="parentTab"></component>
				</template>
				<template slot-scope="props">
					<ModuleArea :alias="item.areaAlias" :custom="item.isCustom" :bus="bus" :entity="entity" :other-module="module"
						v-for="item in props.col.areas" :key="item.areaAlias" :tab-alias="tab.alias" :parent-tab="parentTab"></ModuleArea>
				</template>
			</Layout>

		</b-tab>

		<slot></slot>
	</b-tabs>
</template>

<script>
import Layout from "../_ui/layout.vue";

export default {
	data: () => ({
		moduleErrors: []
	}),
	components: { Layout },
	inject: ['$validator'],
	computed: {
		mainTabs() {
			var defaultTab = {
				name: this.module.name,
				areas: [],
				alias: null
			};

			var tabs = [defaultTab];

			if (this.module) {
				if (this.module.tabs) {
					var moduleTabs = this.module.tabs.filter(x => x.moduleArea == null);
					tabs = tabs.concat(moduleTabs);
				}
				if (this.module.externalTabs) {
					var externalTabs = this.module.externalTabs.filter(x => x.moduleArea == null);
					tabs = tabs.concat(externalTabs);
				}

				tabs.forEach(tab => {
					tab.areas = [];

					if (this.module.areas) {
						var areas = this.module.areas.filter(x => x.moduleArea == tab.alias && x.areaType == "area");
						tab.areas = tab.areas.concat(areas);
					}
					if (this.module.customAreas) {
						var areas = this.module.customAreas.filter(x => x.moduleArea == tab.alias);
						tab.areas = tab.areas.concat(areas);
					}
					if (this.module.externalAreas) {
						var areas = this.module.externalAreas.filter(x => x.moduleArea == tab.alias && x.areaType == "area");
						tab.areas = tab.areas.concat(areas);
					}

					var tw = 0;
					var y = 0;
					var x = 0;

					tab.areas = tab.areas.sort((x, y) => x.sort - y.sort).map((area, index) => {
						var w = x.width || 4;
						var h = 1;
						tw += w;

						var item = {
							...area,
							i: index, x: x, y: y, w: w, h: h
						};
						
						if (tw >= 12) {
							tw = 0;
							x = 0;
							y++;
						} else {
							x += w;
						}
						return item;
					});
				});
			}
			
			return tabs;
		},
		areas() {
            return this.module.areas;
        }
	},
	watch: {},
	props: {
		"entity": Object,
		"bus": Object,
		"parentTab": String
	},
	methods: {
        getLayout(alias) {
            if (this.module.layouts) {
                var layout = this.module.layouts.find(x => x.areaAlias == alias);
                if (layout) {
                    return layout.rows;
                }
            }
            return [];
		},
		area(alias) {
			return this.areas.find(x => x.alias == alias);
        },
		addError(tab, alias, parentTab) {			
			var error = this.moduleErrors.find(x => x.tab == tab && x.parentTab == parentTab);
			if (error) {
				var field = error.fields.findIndex(x => x == alias);
				if (field == -1) {
					error.fields.push(alias);
				}
			} else {
				this.moduleErrors.push({
					tab: tab,
					parentTab: parentTab,
					fields: [alias]
				});
			}
		},
		removeError(tab, alias, parentTab) {
			var error = this.moduleErrors.find(x => x.tab == tab && x.parentTab == parentTab);
			if (error) {
				var field = error.fields.findIndex(x => x == alias);
				if (field >= 0) {
					error.fields.splice(field, 1);
				}
				if (error.fields.length <= 0) {
					var idx = this.moduleErrors.indexOf(error);
					this.moduleErrors.splice(idx, 1);
				}
			}
		},
		hasModuleError(tab) {
			if (!this.parentTab) {
				var tabError = this.moduleErrors.find(x => x.parentTab == tab.alias);
				if (tabError) {
					return true;
				}
				var error = this.moduleErrors.find(x => x.tab == tab.alias && x.parentTab == this.parentTab);
				if (error) {
					return true;
				}
			} else {
				var error = this.moduleErrors.find(x => x.tab == tab.alias);
				return error != null;
			}
			return false;
		}
	},
	created() {
		if (this.bus) {
			this.bus.$on('addError', this.addError);
			this.bus.$on('removeError', this.removeError);
		}
	},
	mounted() {}
};
</script>