<template>
    <div class="files notes" v-if="entity.Id">
        <div class="fakeInput" v-if="!showInput" @click="showInput = true; upload.text = null;">Klik her for at uploade filer...</div>



        <div class="d-block clearfix" v-if="showInput">
            
            <div class="file-upload box" v-cloak @drop.prevent="filesDropped" @dragover.prevent>
                <div class="box__input">
                    <label>
                        <input class="box__file" type="file" name="files" multiple @change="filesSelected" />

                        <span class="fa fa-download"></span>
                        <span v-if="upload.files.length == 0">
                            <strong>Vælg en fil</strong><span class="box__dragndrop"> eller træk den hertil</span>.
                        </span>
                        <span v-else>
                            <span v-for="item in upload.files" :key="item.name" class="d-block">
                                {{item.name}} <strong>{{fileSize(item.size)}}</strong>
                            </span>
                            
                        </span>
                        
                    </label>
                </div>
            </div>

            <v-editor name="note" v-model="upload.text" :init="tinySettings"></v-editor>
            <b-button class="m-1 float-right btn-sm" variant="success" @click="createFile"><span class="fa fa-upload"></span> Upload filer</b-button>
            <b-button class="m-1 float-right btn-sm" variant="secondary" @click="showInput = false; upload.text = null; upload.files = [];"><span class="fa fa-times"></span> Fortryd</b-button>
        </div>

        <b-card-body>
            <div class="note" v-for="item in files" :key="item.Id" v-bind:class="{ showText: item.Id == showText }">
                <div class="date-wrapper">
                    <span>{{$moment(item.CreateDate).format("DD. MMMM YYYY HH:mm")}}</span>
                    <span class="separator">·</span>
                    <span>{{getUser(item.userId)}}</span>
                </div>
                <div class="action-buttons">
                    <b-button class="btn-sm" variant="default" @click="toggleText(item.Id)">
                        <span v-if="item.Id != showText" class="fa fa-chevron-down"></span>
                        <span v-if="item.Id == showText" class="fa fa-chevron-up"></span>
                    </b-button>
                    <b-button class="btn-sm" variant="default" @click="deleteFile(item.Id)"><span class="fa fa-times"></span></b-button>
                </div>
                <div class="note-wrapper">
                    <div class="files">
                        <a :href="file" target="_blank" class="file" v-for="file in item.files" :key="file">
                            <img :src="file" v-if="isImage(file)" />
                            <span class="is-file" v-else><span class="fa fa-file"></span></span>
                            <span class="fa fa-file"></span> {{fileName(file)}}
                        </a>
                    </div>
                    <div class="foldable">
                        <div class="note-content" v-html="item.text"></div>
                    </div>
                    <div class="note-fade"><div></div></div>
                </div>
            </div>
        </b-card-body>
    </div>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({
        showInput: false,
        files: [],
        upload: {
            files: [],
            text: null
        },
        showText: null,
        tinySettings: {
            skin: false,
            toolbar1: 'formatselect | bold italic underline | link | bullist numlist | pastetext',
            menubar: false,
            branding: false,
            plugins: 'lists paste link',
            height: 180,
            content_css: '/css/tiny.css',
            resize: false,
            statusbar: false
        }
    }),
    components: {},
    computed: {},
    watch: {
        "entity.Id"() {
            this.getFiles();
        }
    },
    methods: {
        getFiles() {
            if (this.entity.Id) {
                this.$http.get("/api/module/files/" + this.entity.Id).then(x => {
                    this.files = x.data;
                });
            }
        },
        filesSelected(e) {
            this.upload.files = [...e.target.files];
        },
        filesDropped(e) {
            let droppedFiles = e.dataTransfer.files;
            if(!droppedFiles) return;
            this.upload.files = [...droppedFiles];

            
        },
        createFile() {
            var formData = new FormData();           

            for( var i = 0; i < this.upload.files.length; i++ ){
                let file = this.upload.files[i];

                formData.append("files", file);
            }
            if (this.upload.text) {
                formData.append("text", this.upload.text);
            }
            formData.append("module", this.module.alias);

            this.$http.put("/api/module/files/" + this.entity.Id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(x => {
                this.showInput = false;
                this.upload = {
                    text: null,
                    files: []
                };
                this.getFiles();
            });
        },
        deleteFile(id) {
            if (confirm("Er du sikker på at du vil slette denne fil/filer?")) {
                this.$http.delete("/api/module/files/" + id).then(x => {
                    this.getFiles();
                });
            }
        },
        toggleText(id) {
            if (this.showText == id) {
                this.showText = null;
            } else {
                this.showText = id;
            }
        },
        getUser(id) {
			var name = "";
			var user = this.$store.state.users.find(x => x.id == id);
			if (user != null) {
				name = user.name;
			}
			return name;
        },
        fileSize(size) {
            var val = size / 1000;
            var unit = "KB";
            if (val > 999) {
                val /= 1000;
                unit = "MB";
            }
            return Math.ceil(val) + unit;
        },
        fileName(file) {
            var filename = file.replace(/^.*[\\\/]/, '')

            return filename;
        },
        isImage(file) {
            var ext = file.toLowerCase().split('.').pop();
            var imageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];

            return imageTypes.includes(ext);
        }
    },
    created() {
        this.getFiles();
    },
    mounted() {},
    mixins: [mixin]
};
</script>