<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <!-- <b-button variant="secondary">Fortryd ændringer</b-button>
                <b-button variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button> -->
            </div>
        </div>

        <b-row>

            <b-col>
                <b-card v-if="settings">
                    <template #header>
                        <div class="d-flex justify-content-between align-items-center">
                            Indstillinger

                            <b-button variant="success" size="sm" @click="saveSettings">
                                <span class="fa fa-check"></span> Gem
                            </b-button>
                        </div>
                    </template>

                    <b-form-checkbox v-model="settings.smsActive" name="smsActive" switch>
                        Aktiver SMS udsendelse
                    </b-form-checkbox>

                </b-card>
            </b-col>

            <b-col>

            </b-col>

            <b-col>

            </b-col>
        </b-row>

    </div>
</template>

<script>
    export default {
        data: () => ({
            keys: [],
            settings: null
        }),
        components: {},
        computed: {},
        methods: {
            getSettings() {
                this.$http.get("/api/dashboard/settings").then(x => {
                    this.settings = x.data
                    if (!this.settings) {
                        this.settings = {}
                    }
                })
            },
            saveSettings() {
                this.$http.post("/api/dashboard/settings", this.settings).then(x => {
                    this.getSettings()
                })
            },

        },
        created() {
            this.getSettings()
        },
        mounted() { }
    };
</script>