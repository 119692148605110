<template>
    <div>
        <FormGroup label="Printer">
            <b-form-select ref="printer" v-model="form.printer" :options="printers"></b-form-select>
        </FormGroup>

        <FormGroup label="Stregkode">
            <b-form-input ref="barcode" type="text" v-model="form.barcode" @change="change" autocomplete="off" :readonly="form.order != null"></b-form-input>
        </FormGroup>

        <div v-if="form.order">

            <h3>Ordre: {{form.order.number}}</h3>
            <p>
                Levering:
                <br />
                {{form.order.shippingMethod}}
            </p>

            <FormGroup label="Handling">
                <b-form-input ref="action" type="text" v-model="form.action" @change="actionChange" autocomplete="off" :readonly="!actionEnabled"></b-form-input>
            </FormGroup>
        </div>

        <b-alert variant="danger" :show="form.error != null">{{form.error}}</b-alert>
        <b-alert variant="success" :show="form.message != null">{{form.message}}</b-alert>

    </div>
</template>

<script>
    import TextEditor from "../../_ui/editors/text";

    export default {
        data: () => ({
            form: {
                printer: null,
                barcode: null,
                order: null,
                action: null,
                error: null,
                message: null
            },
            actionEnabled: false,
            printers: []
        }),
        components: { TextEditor },
        computed: {},
        watch: {
            "form.printer"(val) {
                if (window.localStorage) {
                    localStorage.setItem("printer", val)
                }
            }
        },
        props: [],
        methods: {
            change() {
                this.$http.get("/api/module/smartweborders/order", {
                    params: {
                        id: this.form.barcode
                    }
                }).then(x => {
                    if (x.data.number) {
                        this.form.order = x.data;
                        this.form.error = null;
                        setTimeout(() => {
                            this.$refs.action.focus();
                            this.actionEnabled = true;
                        }, 200);
                    } else {
                        this.form.barcode = null;
                        this.form.order = null;
                        this.form.action = null;
                        this.form.message = null;

                        this.form.error = x.data;
                    }
                });
            },
            actionChange() {
                this.actionEnabled = false;
                this.$http.post("/api/module/smartweborders/scan/action", null, {
                    params: {
                        id: this.form.barcode,
                        action: this.form.action,
                        printer: this.form.printer
                    }
                }).then(x => {

                    if (x.data.startsWith("print-shipping-label") || x.data.startsWith("print+shipping+label")) {
                        this.actionEnabled = true;
                        this.form.action = null;
                        this.form.message = "Label bestilt!";

                        setTimeout(() => {
                            this.$refs.action.focus();
                        }, 200);
                    } else if (x.data == "complete-order" || x.data == "complete+order") {
                        this.form.barcode = null;
                        this.form.order = null;
                        this.form.action = null;
                        this.form.message = "Ordre afsluttet! Scan næste ordre.";

                        setTimeout(() => {
                            this.$refs.barcode.focus();
                        }, 200);
                    } else {
                        this.form.barcode = null;
                        this.form.order = null;
                        this.form.action = null;
                        this.form.message = null;

                        this.form.error = x.data;
                    }
                })
                    .catch(function (error) {
                        this.actionEnabled = true;
                        this.form.action = null;
                        setTimeout(() => {
                            this.$refs.action.focus();
                        }, 200);

                        this.form.error = "Der opstod en ukendt fejl.";
                    });
            },
            cancel() {
                this.$emit("close");
            },
            shown() {
                this.$refs.barcode.focus()
            },
            getPrinters() {
                this.$http.get("/api/module/smartweborders/scan/printers").then(x => {
                    this.printers = x.data;

                    if (window.localStorage) {
                        var printer = localStorage.getItem("printer")
                        if (printer && x.data.find(x => x.value == printer)) {
                            this.form.printer = printer;
                        } else {
                            this.form.printer = x.data[0].value;
                        }
                    }
                });
            }
        },
        created() {
            this.getPrinters();
        },
        mounted() {
        },
    };
</script>