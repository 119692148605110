import DefaultContainer from './containers/DefaultContainer'
import Dashboard from './views/dashboard/dashboard'

import Admin from './views/admin/admin'
import AdminClients from './views/admin/clients'
import AdminClient from './views/admin/client'
import AdminModules from './views/admin/modules'
import AdminModule from './views/admin/module'
import AdminUsers from './views/admin/users'

import Login from './views/login/login'
import PageNotFound from './views/404'

import Module from './views/module'

const RouterView = { render (c) { return c('router-view') } };

import { routes as InstalledModules } from './modules';

export const routes = [
    { path: '/login', name: "login", component: Login },

    { path: "/", component: DefaultContainer, children: [
        { path: "", component: Dashboard, name: "dashboard", meta: { label: "Dashboard" } },

        { path: "/admin", component: RouterView, meta: { label: "Administration" }, redirect: "/admin/clients", children: [
            
            { path: "clients", component: RouterView, meta: { label: "Kunder" }, children: [
                { path: "", component: AdminClients },
                { path: "create", component: AdminClient, name: "admin-client-create", meta: { label: "Opret kunde" } },
                { path: ":id", component: AdminClient, name: "admin-client", meta: { label: "Kunde" } },
            ] },

            { path: "modules", component: RouterView, meta: { label: "Moduler" }, children: [
                { path: "", component: AdminModules },
                { path: "create", component: AdminModule, name: "admin-module-create", meta: { label: "Opret module" } },
                { path: ":id", component: AdminModule, name: "admin-module", meta: { label: "Modul" } },
            ] },

            { path: "users", component: AdminUsers, name: "admin-users", meta: { label: "Brugere" } },
        ] },

        { path: '404', component: PageNotFound, name: "404" },

        { path: "", component: Module, children: InstalledModules },

    ] },

    { path: '*', redirect: "404" }
    
]
