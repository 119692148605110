import "./style.scss";

import Vue from 'vue'
const routes = [];
//routes.push({ path: "organizations", redirect: "organizations/ting" });
const RouterView = { render (c) { return c('router-view') } };

import QuickActions from "./quickActions";
Vue.component("qa-products", QuickActions);

import details from "./areas/details";
Vue.component("productDetails", details);
import variants from "./areas/variants";
Vue.component("productVariants", variants);
import productgroupDetails from "./areas/productgroupDetails";
Vue.component("productgroupDetails", productgroupDetails);
import productspecDetails from "./areas/productspecDetails";
Vue.component("productspecDetails", productspecDetails);
import productSpecs from "./areas/productSpecs";
Vue.component("productSpecs", productSpecs);

import Products from "./products";
import Product from "./product";
import ProductGroups from "./productgroups";
import ProductGroup from "./productgroup";
import ProductSpecs from "./productSpecs";
import ProductSpec from "./productSpec";

routes.push({ path: "products", component: RouterView, meta: { label: "Produkter" }, children: [
    { path: "", component: Products, name: "products", meta: { module: "products" } },
    { path: "productgroups", component: ProductGroups, name: "productgroups", meta: { label: "Varegrupper", module: "products" } },
    { path: "productgroups/:id", component: ProductGroup, name: "productgroup", meta: { label: "Varegruppe", module: "products" } },
    { path: "productspecs", component: ProductSpecs, name: "productspecs", meta: { label: "Specifikationer", module: "products" } },
    { path: "productspecs/:id", component: ProductSpec, name: "productspec", meta: { label: "Specifikation", module: "products" } },
    { path: ":id", component: Product, name: "product", meta: { label: "Produkt", module: "products" } }
] });



import store from './store';

export { routes, store };
