import axios from 'axios';

// const GET_USERS = "GET_USERS";

export default {
    namespaced: true,
    state: {
        // users: []
    },
    actions: {
        // [GET_USERS]: ({ commit, dispatch }) => {
        //     axios.get("/api/users/all").then(x => {
        //         commit(GET_USERS, x.data);
        //     });
        // },
    },
    mutations: {
        // [GET_USERS]: (state, data) => {
        //     state.users = data;
        // },
    }
}


// this.$store.dispatch("organizations/GET_USERS");
