<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb :title="editModule.name" />

			<div class="buttons">
				<b-button to="/admin/modules" exact variant="secondary">Fortryd ændringer</b-button>
				<b-button @click="saveChanges" variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button>
			</div>
		</div>

		<masonry :cols="{default: 2, 768: 1}" :gutter="10">

			<b-card no-body>
				<div slot="header">
					<i class="fa fa-info-circle"></i> Detaljer
				</div>
				<b-card-body>
					<div class="row">
						<div class="col-12 col-xl-6">
							<FormGroup label="Navn" label-cols-md="3" :error="errors.first('name')">
								<b-form-input type="text" v-model="editModule.name" :state="$isValid('name')"
									v-validate="'required'" name="name" data-vv-as="Navn"></b-form-input>
							</FormGroup>
						</div>
						<div class="col-12 col-xl-6">
							<FormGroup label="Alias" label-cols-md="3" :error="errors.first('alias')">
								<b-form-input type="text" v-model="editModule.alias" :state="$isValid('alias')"
									v-validate="'required'" name="alias" data-vv-as="Alias"></b-form-input>
							</FormGroup>
						</div>
						<div class="col-12">
							<FormGroup label="Krævede moduler" label-cols-md="3" :label-cols="3">
								<OptifySelect :options="modules" v-model="editModule.requiredModules" :multiple="true" placeholder="Vælg moduler"
								 label="name" track-by="id" autocomplete="off"></OptifySelect>
							</FormGroup>
						</div>
						<div class="col-12 col-xl-6">
							<FormGroup label="Quick action" label-cols-md="3">
								<b-form-checkbox style="margin: 5px 0 0 0" v-model="editModule.quickAction">
									Tilføj
								</b-form-checkbox>
							</FormGroup>
						</div>
						<div class="col-12 col-xl-6">
							<FormGroup label="Navn ental" label-cols-md="3" :error="errors.first('nameSingular')">
								<b-form-input type="text" v-model="editModule.nameSingular" :state="$isValid('nameSingular')"
									v-validate="'required'" name="nameSingular" data-vv-as="Navn ental"></b-form-input>
							</FormGroup>
						</div>
					</div>
				</b-card-body>
			</b-card>

			<b-card no-body>
				<div slot="header">
					<i class="fa fa-grip-horizontal"></i> Områder
				</div>
				<div class="card-tabs">
					<b-tabs card nav-wrapper-class="w-40">
						<b-tab class="p-0" v-for="tab in areaTabs" :key="tab.value">
							<template slot="title">
								<i class="fa fa-cube"></i> {{tab.name}}
							</template>		
							<div class="table-responsive">
								<table class="table table-striped table-md">
									<thead>
										<tr>
											<th>Navn</th>
											<th>Alias</th>
											<th>Komponent</th>
											<th>Menu</th>
											<th>Område</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, key) in editModule.areas.filter(x => x.areaType == tab.value)" :key="'area-' + key">
											<td>{{item.name}}</td>
											<td>{{item.alias}}</td>
											<td>{{item.component}}</td>
											<td>{{$find(moduleMenus, "value", item.menuId, "text")}}</td>
											<td>{{$find(moduleAreas, "value", item.moduleArea, "text")}}</td>
											<td class="text-right">
												<b-button @click="addArea(item)" size="sm" variant="primary"><i class="fa fa-edit"></i></b-button>
												<b-button @click="removeArea(item)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>					
						</b-tab>
					</b-tabs>
				</div>		
				<div slot="footer">
					<b-button @click="addArea" type="button" size="sm" variant="primary"><i class="fa fa-plus"></i> Tilføj område</b-button>
				</div>
			</b-card>
			
			<b-card no-body>
				<div slot="header">
					<i class="fa fa-sitemap"></i> Menu
				</div>
				<div class="table-responsive">
					<table class="table table-striped table-md">
						<thead>
							<tr>
								<th>Titel</th>
								<th>Komponent</th>
								<th>Prioritet</th>
								<th>Ikon</th>
								<th>Synlig</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, key) in editModule.menu" :key="'menu-' + key">
								<td><input type="text" v-model="item.title" class="form-control" /></td>
								<td><input type="text" v-model="item.component" class="form-control" /></td>
								<td><input type="text" v-model="item.sort" class="form-control" /></td>
								<td><input type="text" v-model="item.icon" class="form-control" /></td>
								<td><b-form-checkbox style="margin: 5px 0 0 10px;" v-model="item.visible"></b-form-checkbox></td>
								<td class="text-right">
									<b-button @click="removeMenu(key)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div slot="footer">
					<b-button @click="addMenu" size="sm" variant="primary"><i class="fa fa-plus"></i> Tilføj menu</b-button>
				</div>
			</b-card>
			
			<b-card no-body>
				<div slot="header">
					<i class="fa fa-edit"></i> Felter
				</div>
				<div class="card-tabs">
					<b-tabs card nav-wrapper-class="w-40">
						<b-tab class="p-0" v-for="tab in fieldsTabs" :key="tab.value">
							<template slot="title">
								<i class="fa fa-cube"></i> {{tab.name}}
							</template>
							<div class="table-responsive">
								<table class="table table-striped table-md">
									<thead>
										<tr>
											<th></th>
											<th>Navn</th>
											<th>Alias</th>
											<th>Type</th>
											<th v-if="tab.value == null">Modul</th>
											<th v-if="tab.value == null">Område</th>
											<th class="text-right">Filtrerbar</th>
											<th></th>
										</tr>
									</thead>
									
									<draggable :handle="'.sort-col'" tag="tbody" group="fields" ghost-class="ghost" @end="dragEnd($event, tab)">
										<tr v-for="(item, key) in sortedFields(tab)" :key="'field-' + key">
											<td class="sort-col">
												<i class="fa fa-sort"></i>
											</td>
											<td>{{item.name}}</td>
											<td>{{item.alias}}</td>
											<td>
												{{$find(editorTypes, "value", item.type, "text")}}
											</td>
											<td v-if="tab.value == null">
												{{$find(modules, "id", item.moduleAlias, "name")}}
											</td>
											<td v-if="tab.value == null">
												{{$find(moduleAreas, "value", item.areaId, "text")}}
											</td>
											<td class="text-right">
												{{item.filterable ? "Ja" : "Nej"}}
											</td>
											<td class="text-right">
												<b-button @click="addField(item)" size="sm" variant="primary"><i class="fa fa-edit"></i></b-button>
												<b-button @click="removeField(item)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
											</td>
										</tr>
									</draggable>
								</table>
							</div>
						</b-tab>
					</b-tabs>
				</div>
				<div slot="footer">
					<b-button @click="addField" size="sm" variant="primary"><i class="fa fa-plus"></i> Tilføj felt</b-button>
				</div>
			</b-card>

		</masonry>

		<b-modal id="moduleModal" title="Rediger område" @ok="saveArea" size="lg">
			<div class="row" v-if="editArea">
				<div class="col-12 col-xl-6">
					<FormGroup label="Navn" label-cols-md="3">
						<b-form-input type="text" v-model="editArea.name"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Alias" label-cols-md="3">
						<b-form-input type="text" v-model="editArea.alias"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Komponent" label-cols-md="3">
						<b-form-input type="text" v-model="editArea.component"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Css class" label-cols-md="3">
						<b-form-input type="text" v-model="editArea.class"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Menu" label-cols-md="3">
						<b-form-select v-model="editArea.menuId" :options="moduleMenus" class="" value-field="value" text-field="text" />
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Område" label-cols-md="3">
						<b-form-select v-model="editArea.moduleArea" :options="areaModuleAreas" class="" value-field="value" text-field="text">
							<template slot="first">
								<option :value="null"></option>
							</template>
						</b-form-select>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Type" label-cols-md="3">
							<b-form-select v-model="editArea.areaType" :options="areaTypes" class="" value-field="value" text-field="text" />
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Størrelse" label-cols-md="3">
						<b-form-input type="number" v-model="editArea.width"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Prioritet" label-cols-md="3">
						<b-form-input type="number" v-model="editArea.sort"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Ikon" label-cols-md="3">
						<b-form-input type="text" v-model="editArea.icon"></b-form-input>
					</FormGroup>
				</div>
			</div>

		</b-modal>

		
		<b-modal id="fieldModal" title="Rediger felt" @ok="saveField" size="lg">
			<div class="row" v-if="editField">
				<div class="col-12 col-xl-6">
					<FormGroup label="Navn" label-cols-md="3">
						<b-form-input type="text" v-model="editField.name"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Alias" label-cols-md="3">
						<b-form-input type="text" v-model="editField.alias"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Type" label-cols-md="3">
						<b-form-select :options="editorTypes" v-model="editField.type" class="">
							<template slot="first">
								<option :value="null"></option>
							</template>
						</b-form-select>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Widget" label-cols-md="3">
						<b-form-select
							:options="fieldAreas" 
							v-model="editField.areaId" class="" value-field="value" text-field="text">
							<template slot="first">
								<option :value="null"></option>
							</template>
						</b-form-select>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Modul" label-cols-md="3">
						<b-form-select :options="modules.filter(x => x.id != moduleId)" v-model="editField.moduleAlias" class="" value-field="id" text-field="name">
							<template slot="first">
								<option :value="null"></option>
							</template>
						</b-form-select>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Filtrerbar" label-cols-md="3">
						<b-form-checkbox v-model="editField.filterable" class="mt-1" />
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6" v-if="editField.type == 'select'">
					<FormGroup label="Værdier" label-cols-md="3">
						<b-input-group>
							<b-form-input :ref="'value-' + editField.alias"></b-form-input>
							<b-input-group-append>
								<b-btn variant="success" @click="addValue(editField, 'value-' + editField.alias)">Tilføj</b-btn>
							</b-input-group-append>
						</b-input-group>

						<draggable v-model="editField.values">
							<p class="m-0 mb-1" v-for="(val, idx) in editField.values" :key="idx">
								{{val}} <i class="fa fa-times" @click="editField.values.splice(idx, 1)"></i>
							</p>
						</draggable>
					</FormGroup>
				</div>
			</div>

		</b-modal>
	</div>
</template>

<script>
export default {
	data: () => ({
		editModule: {
			name: null,
			nameSingular: null,
			alias: null,
			menu: [],
			areas: [],
			requiredModules: [],
			fields: [],
			quickAction: false
		},
		areaTypes: [
			{ text: "Tab", value: "tab" },
			{ text: "Widget", value: "area" },
			{ text: "Widget content", value: "areaInline" },
			{ text: "Button area", value: "buttonArea" },
		],
		editArea: null,
		editField: null,
		moduleAreas: [],
		moduleMenus: [],
		modules: []
	}),
	components: {},
	computed: {
		moduleId() {
			return this.$route.params.id;
		},
		editing() {
			return this.$route.params.id != null;
		},
		selectedModules() {
			return this.modules.filter(x => this.editModule.requiredModules.includes(x.id));
		},
		editorTypes() {
			return this.$store.state.editorTypes;
		},
		areaTabs() {
			return [
				{ name: "Tabs", value: "tab" },
				{ name: "Widgets", value: "area" },
				{ name: "Widget content", value: "areaInline" },
				{ name: "Button areas", value: "buttonArea" }
			];
		},
		fieldsTabs() {
			var tabs = [];
			this.editModule.areas.forEach(x => {				
				if ((x.areaType == "area" || x.areaType == "areaInline") || (x.areaType == "tab" && x.moduleArea != null)) {
					tabs.push({ name: x.name, value: x.alias });
				}
			});
			tabs.push({ name: "Eksterne", value: null });
			return tabs;
		},
		fieldAreas() {
			return this.moduleAreas.filter(x => 
				(x.type == 'area' || x.type == 'areaInline') && 
				(x.moduleId == this.editField.moduleAlias || 
					(this.editField.moduleAlias != null && this.moduleMenus.find(e => e.value == x.menuId && e.moduleId == this.editField.moduleAlias)) || 
					(this.editField.moduleAlias == null && x.moduleId == this.moduleId)
				)
			);
		},
		areaModuleAreas() {
			return this.moduleAreas.filter(x =>
				x.menuId == this.editArea.menuId
			);
		}
	},
	methods: {
		dragEnd(e, tab) {
			var items = Object.assign(this.sortedFields(tab), {}).sort((x,e) => x.sort - e.sort);
			var item = items[e.oldIndex];
			items.splice(e.newIndex, 0, items.splice(e.oldIndex, 1)[0]);
			items.forEach((x, i) => {
				var field = this.editModule.fields.find(e => e.alias == x.alias);
				field.sort = i+1;
			});
		},
		sortedFields(tab) {
			var fields = this.editModule.fields
				.filter(x => x.areaId == tab.value || (tab.value == null && x.moduleAlias != null));

			fields.forEach(x => {
				if (x.sort == 0) {
					x.sort = fields.findIndex(e => e.alias == x.alias) + 1;
				}
			});
			return fields;
		},
		getItem() {
			this.$http.get("/api/admin/module/" + this.$route.params.id).then(x => {
				this.editModule = x.data;
				if (!this.editModule.requiredModules)
					this.editModule.requiredModules = [];
			});
		},
		addMenu() {
			this.editModule.menu.push({
				title: null,
				component: null,
				sort: 100,
				icon: null
			});
		},
		removeMenu(item) {
			this.editModule.menu.splice(item, 1);
		},
		async addArea(area) {
			this.$root.$emit('bv::show::modal','moduleModal');

			if (area.alias) {
				this.editArea = Object.assign({}, area);
			} else {
				this.editArea = {
					name: null,
					alias: await this.createId(),
					component: null,
					class: null,
					moduleArea: null,
					areaType: null,
					sort: 100,
					icon: null,
					width: 2
				};
			}
		},
		saveArea() {
			var area = this.editModule.areas.findIndex(x => x.alias == this.editArea.alias);
			if (area == -1) {
				this.editModule.areas.push(this.editArea);
			} else {
				this.$set(this.editModule.areas, area, this.editArea)
			}
			this.editArea = null;
		},
		removeArea(item) {
			var idx = this.editModule.areas.findIndex(x => x.alias == item.alias);
			this.editModule.areas.splice(idx, 1);

			this.editModule.fields.filter(x => x.areaId == item.alias).forEach(x => {
				this.removeField(x);
			});
		},
		async addField(field) {
			this.$root.$emit('bv::show::modal','fieldModal');

			if (field.alias) {
				this.editField = Object.assign({}, field);
			} else {
				this.editField = {
					name: null,
					alias: await this.createId(),
					type: null,
					values: [],
					filterable: false
				};
			}
		},
		saveField() {
			var field = this.editModule.fields.findIndex(x => x.alias == this.editField.alias && x.areaId == this.editField.areaId);
			if (field == -1) {
				this.editModule.fields.push(this.editField);
			} else {
				this.$set(this.editModule.fields, field, this.editField)
			}
			this.editField = null;
		},
		removeField(item) {
			var idx = this.editModule.fields.findIndex(x => x.alias == item.alias && x.areaId == item.areaId);
			this.editModule.fields.splice(idx, 1);
		},
		addValue(item, key) {
			item.values.push(this.$refs[key].$el.value);
			this.$refs[key].$el.value = null;
		},
		saveChanges() {
			this.$http.post("/api/admin/modules/update", this.editModule).then(x => {
				if (x.data !== false) {
					this.showSuccessMsg();

					if (!this.editing) {
						this.$router.replace("/admin/modules/" + x.data)
					}
				} else {
					this.showErrorMsg();
				}
			});
		}
	},
	created() {
		this.$http.get("/api/admin/module-areas").then(x => {
			this.moduleAreas = x.data;
		});

		this.$http.get("/api/admin/module-menus").then(x => {
			this.moduleMenus = x.data;
		});

		this.$http.get("/api/admin/module-list").then(x => {
			this.modules = x.data;
		});

		if (this.editing) {
			this.getItem();
		}
	},
	mounted() {}
};
</script>