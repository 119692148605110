import Vue from 'vue'

Vue.mixin({
    data: () => ({
        customFields: []
    }),
    props: ["otherModule"],
    computed: {
		module() {
			return this.otherModule || this.$store.state.module;
        },
        $users() {
			return this.$store.state.users;
        },
        $roles() {
			return this.$store.state.roles;
        }
	},
    methods: {
        $isValid(name, scope) {
            var field = this.veeFields[name];
            if (scope) {
                if (this.veeFields["$" + scope]) {
                    field = this.veeFields["$" + scope][name];
                } else {
                    return null;
                }
            }
            if (field) {
                if (field.validated) {
                    return field.valid;
                }
            }
            return null;
        },
        async createId() {
            return (await this.$http.get("/api/create-id")).data;
        },
        async asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        },
        getCustomFields() {
            this.$http.get("/api/module/" + this.$route.meta.module + "/fields").then(x => {
                this.customFields = x.data;
            });
        },
        $find(array, key, value, prop) {
            var item = array.find(x => x[key] == value);
            if (item) {
                return item[prop];
            } else {
                return null;
            }
        },
        $user(id) {
            var usr = this.$users.find(x => x.id == id);
            if (usr) {
                return usr;
            }
            return {};
        },
        showSuccessMsg(message, title) {
            this.$bvToast.toast(message ? message : 'Ændringerne blev gemt!', {
				title: title ? title : "Succes!",
				variant: "success",
				solid: true,
				autoHideDelay: 1500
			});
        },
        showInfoMsg(message, title) {
            this.$bvToast.toast(message, {
				title: title,
				variant: "info",
				solid: true,
				autoHideDelay: 1500
			});
        },
        showWarnMsg(message, title) {
            this.$bvToast.toast(message, {
				title: title,
				variant: "warning",
				solid: true,
				autoHideDelay: 1500
			});
        },
        showErrorMsg(message, title) {
            this.$bvToast.toast(message ? message : "Der opstod en ukendt fejl!", {
				title: title ? title : "Fejl!",
				variant: "danger",
				solid: true,
				autoHideDelay: 1500
			});
        }
    }
});