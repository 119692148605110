<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<ButtonAreas />
			</div>
		</div>

		<suitsyouMeasurements></suitsyouMeasurements>
	</div>
</template>

<script>
export default {
	data: () => ({
	}),
	components: {},
	computed: {},
	methods: {},
	created() {},
	mounted() {}
};
</script>