<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" v-slot:default="props" :scope="scope" v-if="(f.access || access) > 0">
		<multiselect :options="f.values || values" v-model="entity[props.alias]" :multiple="true" :disabled="(f.access || access) < 30"
			:allow-empty="!validate" :close-on-select="false" :name="props.alias" :show-labels="false" autocomplete="off"></multiselect>
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {
	},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		validate: Boolean,
		values: Array,
		access: {
			type: Number,
			default: 60
		}
	},
	methods: {},
	created() {
		if (this.item) {
			this.f = this.item;
		}
	},
	mounted() {}
};
</script>