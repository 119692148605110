<template>
    <div class="related-contacts" v-if="entity.Id">
        <b-card-body>

            <div v-for="item in relations" :key="item.id" class="related-contact">
                <span class="fa fa-building fa-lg"></span> 
                <router-link :to="'/organizations/' + item.id">{{item.name}}</router-link>
                <button class="btn btn-light btn-sm float-right" type="button" @click="removeRelation(item.id)"><i class="fa fa-times"></i></button>
            </div>
            
		</b-card-body>

        <b-card-footer class="text-right">
            <b-button @click="newRelation" size="sm" variant="secondary"><span class="fa fa-plus pr-1"></span> Tilføj virksomhed</b-button>
		</b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import newRelatedOrganization from "../modals/_newRelatedOrganization.vue";

export default {
    mixins: [mixin],
    data: () => ({
        relations: []
    }),
    components: {},
    computed: {},
    watch: {
        "entity.Id"() {
            this.getRelations();
        },
        "entity.organizations"() {
            this.getRelations();
        },
    },
    methods: {
        getRelations() {
            if (this.entity.Id) {
                this.$http.post("/api/module/contacts/related-organizations", { organizations: this.entity.organizations }).then(x => {
                    this.relations = x.data;
                });
            }
        },
        newRelation() {
            this.$dialog.open(newRelatedOrganization, { entity: this.entity }, { 
                title: "Tilføj virksomhed", 
                okTitle: "Gem", 
                onClose: () => {
                    this.getRelations();
                }
            })
        },
        removeRelation(id) {
            var idx = this.entity.organizations.findIndex(x => x == id);
            if (idx > -1) {
                this.entity.organizations.splice(idx, 1);
            }
            // this.$http.delete("/api/module/contacts/related-organization", {
            //     data: {
            //         contactId: this.entity.Id,
            //         organizationId: id
            //     }
            // }).then(x => {
            //     this.getRelations();
            // });
        }
    },
    created() {
        this.getRelations();
    },
    mounted() {
    }
};
</script>