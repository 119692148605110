import "./style.scss";

import Vue from 'vue'
const routes = [];

import files from "./areas/files";
Vue.component("files", files);


import store from './store';

export { routes, store };
