<template>
  <b-card-body>

		<TextEditor :entity="entity" label="Navn" alias="5d139c4a0512d063ecf457fe" :validate="true" scope="module"></TextEditor>

  </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import TextEditor from "../../_ui/editors/text";

export default {
    data: () => ({}),
    components: { TextEditor },
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>