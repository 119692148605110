import Vue from "vue";



export default {
	data: () => ({
		entity: {},
		bus: new Vue({ 
			data: () => ({
				actions: []
			}),
		 }),
	}),
    components: {},
    computed: {},
    methods: {
		resetValidation() {
			this.$validator.reset();
			this.bus.$emit("resetValidation");
        }
	},
	created() {

	},
}