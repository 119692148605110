import "./style.scss";

import Vue from 'vue'
const routes = [];

import notes from "./areas/notes";
Vue.component("notes", notes);


import store from './store';

export { routes, store };
