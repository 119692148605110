<template>
	<div v-if="product">

		<div class="row">
			<div class="col col-4">
				<b-form-checkbox v-model="update.internalExpiry" :value="true" :unchecked-value="false">
					Intern udløbsdato
				</b-form-checkbox>
			</div>
			<div class="col col-8">
				<EditorDate :entity="update" alias="internalExpiryDate" :key="update.internalExpiryDate"></EditorDate>
			</div>
		</div>

		<div class="row">
			<div class="col col-4">
				<b-form-checkbox v-model="update.cooling" :value="true" :unchecked-value="false">
					Udløbsdato
				</b-form-checkbox>
			</div>
			<div class="col col-8">
				<EditorDate :entity="update" alias="coolingDate"></EditorDate>
			</div>
		</div>
		<div class="row">
			<div class="col col-4">
				<b-form-checkbox v-model="update.notification" :value="true" :unchecked-value="false">
					Notifikation
				</b-form-checkbox>
			</div>
			<div class="col col-8">
				<EditorDate :entity="update" alias="notificationDate"></EditorDate>
			</div>
		</div>

		

		<b-alert :variant="stockIn ? 'primary' : 'danger'" :show="true">
			<FormGroup :label="stockIn ? 'Start indscanning af lager' : 'Start udscanning af lager'">
				<b-form-input ref="barcode" type="text" v-model="barcode" @change="change()" autocomplete="off"></b-form-input>
			</FormGroup>
		</b-alert>

		<div class="text-center pb-3" v-if="!product.variants || product.variants.length < 1">
			<div class="input-group mx-auto" style="max-width: 250px">
				<b-form-input type="number" v-model="quantity" autocomplete="off" :min="1"></b-form-input>
				<div class="input-group-append">
					<b-button variant="primary" size="lg" @click="barcode = ''; change(quantity)"><i class="fa fa-plus"></i> Tilføj produkter</b-button>
				</div>
			</div>

		</div>


		<b-alert variant="danger" :show="error != null">{{error}}</b-alert>

		<b-alert variant="success" :show="update.items && update.items.length > 0">
			<strong class="d-block">Indscannet:</strong>

			<p v-for="item in update.items" :key="item.itemId" class="m-0">
				{{item.stock}} stk. {{item.name}}
			</p>
		</b-alert>

		<b-alert variant="success" :show="message != null">{{message}}</b-alert>

		<footer class="text-center">
			<b-button :disabled="loading" v-if="!update.items || update.items.length < 1" size="lg" variant="danger" @click="cancel">Luk</b-button>

			<b-button :disabled="loading" v-if="update.items && update.items.length > 0" size="lg" variant="danger" @click="cancel">Anuller og luk</b-button>
			<b-button :disabled="loading" v-if="changed || (update.items && update.items.length > 0)" size="lg" variant="success" @click="ok">
				<span class="fa mr-1" :class="{ 'fa-save': !loading, 'fa-sync': loading, 'fa-spin': loading }"></span>
				Gem og opdater
			</b-button>
		</footer>

		<div class="mb-3 mt-2" v-if="product.variants && product.variants.length > 0">
			<table class="table table-sm table-striped">
				<thead>
					<tr>
						<th colspan="3" class="text-center">Varianter:</th>
					</tr>
					<tr>
						<th>Navn</th>
						<th>Lager</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in product.variants" :key="item.id">
						<td v-html="item['5ce2e8e046cd0030dcf950e0']"></td>
						<td>{{item["5d1a4a64ce49d323d0b5eda2"]}} stk.</td>
						<td>
							<b-button variant="primary" size="sm" @click="addProduct(item)">Tilføj</b-button>
						</td>
					</tr>
				</tbody>
			</table>

		</div>
		<div v-else class="text-center mb-3">
			<strong>Lager: </strong> {{product.stock}} stk.
		</div>

	</div>
</template>

<script>
import Editors from "../../mixins/editors";

export default {
	data: () => ({
		barcode: null,

		update: {
			id: null,
			itemId: null,
			internalExpiry: false,
			internalExpiryDate: null,
			cooling: false,
			coolingDate: null,
			notification: false,
			notificationDate: null,
			hasVariants: false,
			scanIn: true,
			items: []
		},

		product: null,
		quantity: 1,
		
		error: null,
		message: null,
		loading: false,
		changed: false
	}),
	components: { ...Editors },
	computed: {},
	watch: {
		"update.notification"(newVal, oldVal) {
			if (oldVal !== null) {
				this.changed = true;
			}
		},
		"update.notificationDate"(newVal, oldVal) {
			if (oldVal !== null) {
				this.changed = true;
			}
		},
		"update.cooling"(newVal, oldVal) {
			if (oldVal !== null) {
				this.changed = true;
                if (newVal == false) {
					this.update.coolingDate = null
                }
			}
		},
		"update.coolingDate"(newVal, oldVal) {
			if (oldVal !== null) {
				this.changed = true;
			}
		},
		"update.internalExpiry"(newVal, oldVal) {
			if (oldVal !== null) {
				this.changed = true;
				
                if (newVal == false) {
					this.update.internalExpiryDate = null
                }
			}
		},
		"update.internalExpiryDate"(newVal, oldVal) {
			if (oldVal !== null) {
				this.changed = true;
			}
		},
	},
	props: ["productId", "stockIn"],
	methods: {
		getProduct() {
			this.$http.get("/api/module/mrbeef-lager/product/" + this.productId).then(x => {
				this.product = x.data;

				this.update.id = x.data["_id"];
				this.update.itemId = x.data.sku;
				this.update.cooling = x.data["5e2c6b09329b745cdca400b6"] == "Ja";
				this.update.coolingDate = x.data["5e2c6b1f329b745cdca400bb"];
				this.update.internalExpiry = x.data["65fb79a9cd239776f7faa3fe"] == "Ja";
				this.update.internalExpiryDate = x.data["65fb79e6cd239776f7faa407"];
				this.update.notification = x.data["5e6141c1d7aca023845450f0"] == "Ja";
				this.update.notificationDate = x.data["5e6141e1d7aca023845450f5"];
				this.update.hasVariants = x.data.variants && x.data.variants.length > 0;
				this.update.scanIn = this.stockIn;

				if (this.update.hasVariants) {
					this.product.variants.sort((a,b) => (a["5dd7fa2b52b45f2c74a31575"] > b["5dd7fa2b52b45f2c74a31575"]) ? 1 : -1);					
				}

				this.$nextTick(() => {
					this.$refs.barcode.focus()
				})
			});
		},
		addProduct(variant) {
			var item = this.update.items.find(x => x.itemId == variant["5ce2e8ef46cd0030dcf950e1"]);
			if (item) {
				item.stock += 1;
			} else {
				this.update.items.push({
					stock: 1, 
					itemId: variant["5ce2e8ef46cd0030dcf950e1"], 
					name: variant["5ce2e8e046cd0030dcf950e0"]
				});
			}
		},
		change(qty) {
			var weightStr = this.barcode.slice(-6).slice(0, -1);
			var weight = parseInt(weightStr);

			this.error = null;
			
			if (this.update.hasVariants) {

				var variant = null;

				if (weight >= this.product["5e78e08f2f21322de8a06365"]) {
					for (let index = 0; index < this.product.variants.length; index++) {
						const element = this.product.variants[index];

						var title = element['5ce2e8e046cd0030dcf950e0']
						let matches = title.match(/\d+/g);
						let weigthRange = matches ? matches.map(Number) : [];

						let weigthMin = Math.min(...weigthRange);
						let weigthMax = Math.max(...weigthRange);

                        if ((index+1) == this.product.variants.length) {
							weigthMax++;
                        }

						let isInRange = weight >= weigthMin && weight < weigthMax;

						if (isInRange) {
							variant = element;
							break;
						}
					}
				}

				if (variant) {

					var item = this.update.items.find(x => x.itemId == variant["5ce2e8ef46cd0030dcf950e1"]);
					if (item) {
						item.stock += 1;
					} else {
						this.update.items.push({
							stock: 1, 
							itemId: variant["5ce2e8ef46cd0030dcf950e1"], 
							name: variant["5ce2e8e046cd0030dcf950e0"]
						});
					}
					
				} else {
					this.error = "Ingen variant fundet med indscannet vægt!"
				}

			} else {
				if (!qty || isNaN(qty)) {
					qty = 1;
				}

				qty = parseInt(qty);

				var item = this.update.items.find(x => x.itemId == this.update.itemId);
				if (item) {
					item.stock += qty;
				} else {
					this.update.items.push({
						stock: qty, 
						itemId: this.update.itemId, 
						name: this.product.name
					});
				}

				this.quantity = 1;
			}

			this.barcode = null;			
		},

		cancel() {
			this.$emit("close");
		},
		ok() {
			this.loading = true;

			this.$http.post("/api/module/mrbeef-lager/update-product", this.update).then(x => {
				this.$emit("close")
			}).catch(x => {

			}).finally(x => {
				this.loading = false;
			});
			
		},
		shown() {
			//setTimeout(() => {
			//	this.$refs.barcode.focus()
			//}, 1000)
		}
	},
	created() {
		this.getProduct();
	},
	mounted() {
	},
};
</script>