var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative has-tool-menu"},[_c('div',{staticClass:"tool-menu"},[_c('Breadcrumb'),_c('div',{staticClass:"buttons"},[_c('ButtonAreas',{attrs:{"entities":_vm.selected}}),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.fetchProducts}},[_c('span',{class:{ fa: true, 'fa-download': !_vm.loading, 'fa-sync': _vm.loading, 'fa-spin': _vm.loading }}),_vm._v(" Hent produkter ")])],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"fa fa-user-tie"}),_vm._v(" Produkter "),_c('div',{staticClass:"card-header-actions"})]),(_vm.fields)?_c('DataTable',{ref:"dataTable",attrs:{"url":"/api/module/mrbeef-lager/products","fields":_vm.fields,"name":"products","filter":true,"cache-search":"mrbeef-lager"},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var props = ref.props;
return [_c('b-link',{attrs:{"to":'/products/' + props.row.Id}},[_vm._v(" "+_vm._s(props.row.name)+" ")])]}},{key:"5e2c6b1f329b745cdca400bb",fn:function(ref){
var props = ref.props;
return [(props.row['5e2c6b1f329b745cdca400bb'] && props.row['5e2c6b09329b745cdca400b6'] == 'Ja')?_c('span',[_vm._v(" "+_vm._s(_vm.$moment(props.row['5e2c6b1f329b745cdca400bb']).format("DD-MM-YYYY"))+" ")]):_vm._e()]}},{key:"65fb79e6cd239776f7faa407",fn:function(ref){
var props = ref.props;
return [(props.row['65fb79e6cd239776f7faa407'] && props.row['65fb79a9cd239776f7faa3fe'] == 'Ja')?_c('span',[_vm._v(" "+_vm._s(_vm.$moment(props.row['65fb79e6cd239776f7faa407']).format("DD-MM-YYYY"))+" ")]):_vm._e()]}},{key:"CreateDate",fn:function(ref){
var props = ref.props;
return [_vm._v(" "+_vm._s(_vm.$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm"))+" ")]}},{key:"edit",fn:function(ref){
var props = ref.props;
return [_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.stockIn(props.row)}}},[_c('i',{staticClass:"fa fa-sign-in-alt"}),_vm._v(" Lager ind")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){$event.stopPropagation();return _vm.stockOut(props.row)}}},[_c('i',{staticClass:"fa fa-sign-out-alt"}),_vm._v(" Lager ud")])]}}],null,false,3877119615)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }