<template>
	<b-nav-item class="d-md-down-none" @click="newOrganization">
		<i class="fa fa-building pr-2"></i> Ny virksomhed
	</b-nav-item>
</template>

<script>
import newOrganization from "./modals/_newOrganization.vue";

export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {
		newOrganization() {
			this.$dialog.open(newOrganization, { }, { title: "Ny virksomhed", okTitle: "Opret" })
		}
	},
	created() {},
	mounted() {}
};
</script>