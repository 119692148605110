<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<CButton color="secondary" size="sm">Fortryd ændringer</CButton>
				<CButton color="success" size="sm"><span class="fa fa-save"></span> Gem ændringer</CButton>
			</div>
		</div>
		<p>
			Admin
		</p>
	</div>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {},
	created() {},
	mounted() {}
};
</script>