<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit="login">
                  <h1 class="mb-4">Log ind</h1>
                  <p class="text-muted" v-if="false">Sign In to your account</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-at"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input v-model="form.username" name="username" type="text" class="form-control" placeholder="E-mail" autocomplete="off" />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-unlock-alt"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input v-model="form.password" name="password" type="password" class="form-control" placeholder="Adgangskode" autocomplete="off" />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type="submit" variant="primary" class="px-4">Log ind</b-button>
                    </b-col>
                    <b-col cols="6" class="text-right" v-show="false">
                      <b-button variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <b-card no-body class="text-white bg-dark py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <img src="/img/brand/logo.svg" height="70" alt="Optify" class="mt-4">
                
                <div v-if="false">
                  <h2>Sign up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <b-button variant="primary" class="active mt-3">Register Now!</b-button>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
	data: () => ({
    form: {
      username: null,
      password: null
    }
  }),
	components: {},
	computed: {},
	methods: {
		login(evt) {
      evt.preventDefault();
      this.$http.post("/api/login", this.form).then(x => {
        if (x.data === true) {
          location = "/";
        } else {

        }
      });
    }
	},
	created() {},
	mounted() {}
};
</script>