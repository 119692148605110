<template>
	<v-server-table ref="dataTable" :apiUrl="url" :name="name" :columns="columns" :options="options" @row-click="rowClick">
		<template v-slot:[item]="props" v-for="item in columns">
			<slot :props="props" :name="item">
				<span :key="item" v-if="fields.find(e => e.name == item).type == 'date'">
					{{props.row[item] && props.row[item] != "" ? $moment(props.row[item]).format("DD-MM-YYYY") : ""}}
				</span>
				<span :key="item" v-else>{{props.row[item]}}</span>
			</slot>
		</template>

		<template v-for="item in filters">
			<div :slot="'filter__' + item.name" :key="item.name">
				<TextFilter :item="item" @refresh="refresh" v-if="item.type == 'text' || item.type == 'hidden'" />
				<TextFilter :item="item" @refresh="refresh" v-if="item.type == 'number'" />
				<DateFilter :item="item" @refresh="refresh" v-if="item.type == 'date'" />
				<UserFilter :item="item" @refresh="refresh" v-if="item.type == 'user'" />
				<SelectMultiFilter :item="item" @refresh="refresh" v-if="item.type == 'select'" />
			</div>
		</template>
	</v-server-table>
</template>

<script>
import TextFilter from './tableFilters/text';
import NumberFilter from './tableFilters/number';
import DateFilter from './tableFilters/date';
import UserFilter from './tableFilters/user';
import SelectMultiFilter from './tableFilters/selectMulti';

export default {
	data: () => ({
		options: {
			headings: null,
			sortable: null,
			filterByColumn: false,
			filterable:[],
			requestFunction(data) {
				data.filter = this.$parent.$parent.fields;
				return this.$http.post(this.$parent.$parent.url, data).catch(function (e) {
					this.dispatch('error', e);
				});
			},
			responseAdapter({data}) {
				return data
			},
			orderBy: { ascending:false, column: "CreateDate"  },
			theme: 'bootstrap4',
			sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
			skin: "table table-striped table-sm table-hover",
			columnsClasses: null,
			texts:{
				count:"Viser {from} til {to} af {count} elementer|{count} elementer|1 optegnelse",
				first:'Første',
				last:'Sidste',
				filter:"Filter:",
				filterPlaceholder:"Søg...",
				limit:"Elementer:",
				page:"Side:",
				noResults:"Ingen elementer fundet",
				filterBy:"Filtrér efter {column}",
				loading:'Indlæser...',
				defaultOption:'Vælg {column}',
				columns:'Kolonner'
			},
			perPage: 50,
			rowClassCallback(row) {
				if (row.selected) {
					return "selected"
				} else {
					return "";
				}
			}
		},
		selected: []
	}),
	props: ["fields", "name", "url", "filter", "skin", "cacheSearch"],
	components: { TextFilter, DateFilter, UserFilter, SelectMultiFilter },
	computed: {
		columns() {
			return this.fields.filter(x => x.visible).map(x => x.name);
		},
		headings() {
			return this.fields.filter(x => x.visible).reduce((map, obj) => (map[obj.name] = obj.label, map), {});
		},
		filters() {
			return this.fields.filter(x => x.filter && x.visible);
		},
		sortable() {
			return this.fields.filter(x => (x.visible && x.type == "date") || x.sort).map(x => x.name);
		}
	},
	watch: {
		fields() {
			this.init();
			this.refresh();
		},
		filters: {
			handler() {
				if (this.cacheSearch && localStorage) {
					localStorage.setItem(this.cacheSearch + "-filter", JSON.stringify(this.filters));
				}
			},
			deep: true
		}
	},
	methods: {
		refresh() {
			this.selected = [];
			this.$refs.dataTable.getData();
		},
		init(){
			this.options.filterByColumn = this.filter;
			this.options.headings = this.headings;
			this.options.sortable = this.sortable;
			if (this.skin) {
				this.options.skin = this.skin;
			}
			this.options.columnsClasses = this.fields.filter(x => x.visible).reduce((map, obj) => (map[obj.name] = obj.class, map), {});
		},
		rowClick(e) {
			this.$set(e.row, "selected", !e.row.selected);
			if (e.row.selected) {
				this.selected.push(e.row);
			} else {
				var rowIdx = this.selected.findIndex(x => x.Id == e.row.Id);
				this.selected.splice(rowIdx, 1);
			}
			this.$emit("row-click", this.selected);
		}
	},
	created() {
		this.init();
	},
	mounted() {
		if (this.cacheSearch && localStorage) {
			var searchFilters = localStorage.getItem(this.cacheSearch + "-filter");
			if (searchFilters) {
				try {
					this.searchFilter = JSON.parse(searchFilters);
					for (let index = 0; index < this.searchFilter.length; index++) {
						const element = this.searchFilter[index];
						var filter = this.filters.find(x => x.name == element.name);
						if (filter && filter.filter) {
							filter.value = element.value;
						}
					}
				} catch (error) {}
			}
		}
	}
};
</script>