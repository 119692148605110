<template>
	<ol class="breadcrumb">
		<li class="breadcrumb-item" :key="index" v-for="(routeObject, index) in routeRecords">
			<span class="active" v-if="isLast(index)">{{ title || getName(routeObject) }}</span>
			<router-link :to="routeObject" v-else>{{ getName(routeObject) }}</router-link>
		</li>
	</ol>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {
		list() {
			return this.$route.matched.filter(
				route => route.meta.label
			);
		},
		routeRecords() {
			return this.list.filter(route => route.meta.label);
		}
	},
	props: ["title"],
	methods: {
		getName(item) {
			return item.meta && item.meta.label
				? item.meta.label
				: item.name || null;
		},
		isLast(index) {
			return index === this.list.length - 1;
		}
	},
	created() {},
	mounted() {}
};
</script>