<template>
	<div>
		<DateEditor label="Leveringsdato" :entity="form" alias="date" :validate="true" scope="print"></DateEditor>

		<div>
			<b-form-checkbox v-model="form.shipping">
				Ordrer til levering
			</b-form-checkbox>
			<b-form-checkbox v-model="form.pickup">
				Ordre til afhentning
			</b-form-checkbox>

			<b-form-checkbox v-model="form.butcher">
				Kun ordrer til udskæring (slagter)
			</b-form-checkbox>
		</div>
	</div>
</template>

<script>
import DateEditor from "../../_ui/editors/date";

export default {
	data: () => ({
		form: {
			date: null,
			shipping: false,
			pickup: false,
			butcher: false
		}
	}),
	components: { DateEditor },
	computed: {},
	watch: {},
	props: [],
	methods: {
		ok() {
			this.$validator.validate("print.*").then(valid => {
				if (valid) {
					this.$http({
						url: '/api/module/smartweborders/print',
						params: {
							shippingDate: this.$moment(this.form.date).format("YYYY-MM-DD"),
							shipping: this.form.shipping,
							pickup: this.form.pickup,
							butcher: this.form.butcher,
						},
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						this.$fileDownload(response.data, 'plukliste.pdf');

						this.$emit("ok");
					});
				}
			})
		},
		cancel() {
			this.$emit("close");
		}
	},
	created() {},
	mounted() {},
};
</script>