<template>
	<FormGroup :label="f.name || label" :alias="valAlias" v-slot:default="props" :scope="scope" v-if="(f.access || access) > 0">
		<OptifySelect :options="$users" v-model="entity[props.alias]" :multiple="false" :disabled="(f.access || access) < 30"
			@input="validateField" label="name" track-by="id" autocomplete="off"></OptifySelect>
		<input type="hidden" v-validate="validate ? 'required' : null" v-model="entity[props.alias]" :disabled="(f.access || access) < 30" 
			:class="{ 'is-invalid': !props.state }" :name="props.alias" :data-vv-scope="scope" />
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {
		valAlias() {
			return this.f.alias || this.alias;
		}
	},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		validate: Boolean,
		values: Array,
		access: {
			type: Number,
			default: 60
		}
	},
	methods: {
		validateField(val) {
			this.$nextTick(() => {
				if (this.scope) {
					this.$validator.validate(this.scope + "." + this.valAlias)
				} else {
					this.$validator.validate(this.valAlias)
				}
			})
		}
	},
	created() {
		if (this.item) {
			this.f = this.item;
		}
	},
	mounted() {}
};
</script>