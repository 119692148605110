<template>
    <div class="notes" v-if="entity.Id">
        <div class="fakeInput" v-if="!showInput" @click="showInput = true; note.text = null;">Klik her for at oprette en note...</div>
        <div class="d-block clearfix" v-if="showInput">

            <ckeditor name="note" :editor="editor" v-model="note.text" :config="editorConfig"></ckeditor>

            <b-button class="m-1 float-right btn-sm" variant="success" @click="createNote"><span class="fa fa-save"></span> Opret note</b-button>
            <b-button class="m-1 float-right btn-sm" variant="secondary" @click="showInput = false; note.text = null;"><span class="fa fa-times"></span> Fortryd</b-button>
        </div>

        <b-card-body>
            <div class="note" v-for="item in notes" :key="item.Id" v-bind:class="{ showText: item.Id == showText }">
                <div class="date-wrapper">
                    <span>{{$moment(item.CreateDate).format("DD. MMMM YYYY HH:mm")}}</span>
                    <span class="separator">·</span>
                    <span>{{getUser(item.userId)}}</span>
                </div>
                <div class="action-buttons">
                    <b-button class="btn-sm" variant="default" @click="toggleText(item.Id)">
                        <span v-if="item.Id != showText" class="fa fa-chevron-down"></span>
                        <span v-if="item.Id == showText" class="fa fa-chevron-up"></span>
                    </b-button>
                    <b-button class="btn-sm" variant="default" @click="deleteNote(item.Id)"><span class="fa fa-times"></span></b-button>
                </div>
                <div class="note-wrapper">
                    <div class="foldable">
                        <div class="note-content" v-html="item.text"></div>
                    </div>
                    <div class="note-fade"><div></div></div>
                </div>
            </div>
        </b-card-body>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data: () => ({
        editor: ClassicEditor,
        editorConfig: {
            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
        },

        showInput: false,
        notes: [],
        note: {
            text: null
        },
        showText: null
    }),
    components: {},
    computed: {},
    watch: {
        "entity.Id"() {
            this.getNotes();
        }
    },
    methods: {
        getNotes() {
            if (this.entity.Id) {
                this.$http.get("/api/module/notes/" + this.module.alias + "/" + this.entity.Id).then(x => {
                    this.notes = x.data;
                });
            }
        },
        createNote() {
            this.$set(this.note, "module", this.module.alias);

            this.$http.put("/api/module/notes/" + this.entity.Id, this.note).then(x => {
                this.showInput = false;
                this.getNotes();
            });
        },
        deleteNote(id) {
            if (confirm("Er du sikker på at du vil slette denne note?")) {
                this.$http.delete("/api/module/notes/" + id).then(x => {
                    this.getNotes();
                });
            }
        },
        toggleText(id) {
            if (this.showText == id) {
                this.showText = null;
            } else {
                this.showText = id;
            }
        },
        getUser(id) {
			var name = "";
			var user = this.$store.state.users.find(x => x.id == id);
			if (user != null) {
				name = user.name;
			}
			return name;
		}
    },
    created() {
        this.getNotes();
    },
    mounted() {},
    mixins: [mixin]
};
</script>