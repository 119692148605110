<template>
    <div class="related-contacts">
        <b-card-body>

            <div v-if="relation" class="related-contact">
                <span class="fa fa-building fa-2x"></span> 
                <router-link :to="'/organizations/' + relation.id">{{relation.name}}</router-link>
                <button class="btn btn-light btn-sm float-right" type="button" @click="removeRelation()"><i class="fa fa-times"></i></button>
            </div>
            
		</b-card-body>

        <b-card-footer class="text-right">
            <b-button @click="newRelation" size="sm" variant="secondary"><span class="fa fa-plus pr-1"></span> Tilføj virksomhed</b-button>
		</b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import newRelatedOrganization from "../modals/_newRelatedOrganization.vue";

export default {
    mixins: [mixin],
    data: () => ({
        relation: null
    }),
    components: {},
    computed: {},
    methods: {
        getRelation() {
            this.$http.get("/api/module/orders/related-organization", {
                params: {
                    organizationId: this.entity.organizationId
                }
            }).then(x => {
                this.relation = x.data;
            });
        },
        newRelation() {
            this.$dialog.open(newRelatedOrganization, { entity: this.entity }, { 
                title: "Tilføj virksomhed", 
                okTitle: "Gem", 
                onClose: () => {
                    this.getRelation();
                }
            })
        },
        removeRelation() {
            this.entity.organizationId = null;
            this.relation = null;
        }
    },
    created() {
        this.getRelation();
    },
    mounted() {
    }
};
</script>