import Vue from "vue"
import Vuex from 'vuex'
import axios from "axios"
import router from "./router"
import store from "./store"
import App from "./views/root"
import "./components"
import "./mixins"

import "./scss/style.scss"
import "@fortawesome/fontawesome-free/css/all.css"
import "vue-multiselect/dist/vue-multiselect.min.css"


import BootstrapVue from "bootstrap-vue"
Vue.use(BootstrapVue)

import moment from "moment"
moment.locale("da")

import numeral from "numeral"
import "numeral/locales/da-dk"
numeral.locale("da-dk")

import da from "vee-validate/dist/locale/da"
import VeeValidate, { Validator } from "vee-validate"
Vue.use(VeeValidate, {
    locale: "da",
    fieldsBagName: "veeFields"
})
Validator.localize("da", da)

// import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
// Vue.component('Validation', ValidationProvider)
// Vue.component('ValidationForm', ValidationObserver)


import { ServerTable } from "vue-tables-2"
Vue.use(ServerTable)


import VueMasonry from "vue-masonry-css"
Vue.use(VueMasonry)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
VueCookies.config('7d')

import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor)

import fileDownload from 'js-file-download'

Vue.prototype.$http = axios
Vue.prototype.$moment = moment
Vue.prototype.$numeral = numeral
Vue.prototype.$fileDownload = fileDownload


window.browserIntercept = function browserIntercept(e) {
	e.preventDefault()
    e.returnValue = ''
}

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
