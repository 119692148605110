<template>
    <div class="related-contacts">
        <b-card-body>

            <div v-if="relation" class="related-contact">
                <span class="fa fa-user-circle fa-2x"></span> 
                <router-link :to="'/contacts/' + relation.id">
                    <span class="d-block mb-0">{{relation.name}}</span>
                    <small class="d-block text-dark font-weight-bold">
                        <span v-if="relation.phone">Tlf: {{relation.phone}}</span>
                        <span v-if="relation.email">E-mail: {{relation.email}}</span>
                    </small>

                </router-link>
                <button class="btn btn-light btn-sm float-right" type="button" @click="removeRelation()"><i class="fa fa-times"></i></button>
            </div>
            
		</b-card-body>

        <b-card-footer class="text-right">
            <b-button @click="newRelation" size="sm" variant="secondary"><span class="fa fa-plus pr-1"></span> Tilføj kontakt</b-button>
		</b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import newRelatedContact from "../modals/_newRelatedContact.vue";

export default {
    mixins: [mixin],
    data: () => ({
        relation: null
    }),
    watch: {
        "$route.params.id"() {
            setTimeout(() => {
                this.getRelation();
            }, 300);
        }
    },
    components: {},
    computed: {},
    methods: {
        getRelation() {
            this.$http.get("/api/module/orders/related-contact", {
                params: {
                    contactId: this.entity.contactId
                }
            }).then(x => {
                this.relation = x.data;
            });
        },
        newRelation() {
            this.$dialog.open(newRelatedContact, { entity: this.entity }, { 
                title: "Tilføj kontaktperson", 
                okTitle: "Gem", 
                onClose: () => {
                    this.getRelation();
                }
            })
        },
        removeRelation() {
            this.entity.contactId = null;
            this.relation = null;
        }
    },
    created() {
        this.getRelation();
    },
    mounted() {
    }
};
</script>