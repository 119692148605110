export default {
    inject: ['$validator'],
    components: {},
    props: ["entity", "fields", "bus", "tab", "alias", "parentTab"],
    computed: {
        areas() {
            return this.module.areas;
        }
    },
    watch: {
        "errors.items"() {            
            if (this.bus) {
                if (this.errors.any()) {
                    this.bus.$emit("addError", this.tab, this.alias, this.parentTab);
                } else {
                    this.bus.$emit("removeError", this.tab, this.alias, this.parentTab);
                }
            }
        }
    },
    methods: {
        field(alias) {
			return this.fields.find(x => x.alias == alias);
        },
        area(alias) {
			return this.areas.find(x => x.alias == alias);
        },
        submit() {

        },
        cancel() {

        },
        resetValidation() {
            this.$validator.reset();
        }
    },
    created() {
        if (this.bus) {
			this.bus.$on('submit', this.submit)
			this.bus.$on('cancel', this.cancel)
			this.bus.$on('resetValidation', this.resetValidation)
		}
    },
    mounted() {},
}