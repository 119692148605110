<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<b-button variant="success" to="/admin/clients/create"><span class="fa fa-plus"></span> Opret kunde</b-button>
			</div>
		</div>

		<b-card no-body>
			<div slot="header">
				<i class="fa fa-building"></i> Kunder
				<div class="card-header-actions">
					<!-- <b-link href="#" class="card-header-action btn-setting">
						<i class="fa fa-cog"></i>
					</b-link> -->
				</div>
			</div>
			<b-collapse id="collapse-users" visible>
				<DataTable url="/api/admin/clients" :fields="fields" name="clients" :filter="true">
					<template slot="ParentId" slot-scope="{props}">
						<span v-if="props.row.ParentId">
							{{clients.find(x => x.id == props.row.ParentId).name}}
						</span>
					</template>
					<template slot="edit" slot-scope="{props}">
						<b-button variant="primary" :to="'/admin/clients/' + props.row.Id"><span class="fa fa-pen"></span> Rediger</b-button>
					</template>
				</DataTable>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>

export default {
	data: () => ({
		clients: [],
		fields: [
			{
				name: "Name",
				type: "text",
				label: "Navn",
				filter: true,
				value: null,
				visible: true,
				sort: true
			},
			{
				name: "ParentId",
				type: "text",
				label: "Franchise",
				filter: false,
				visible: true,
			},
			{
				name: "edit",
				visible: true,
				label: "",
				class: "text-right"
			}
		]
	}),
	components: {},
	computed: {},
	methods: {},
	created() {
		this.$http.get("/api/admin/client/clients").then(x => {
			this.clients = x.data;
		});
	},
	mounted() {}
};
</script>