import "./style.scss";

import Vue from 'vue'
const routes = [];

const RouterView = { render (c) { return c('router-view') } };

import QuickActions from "./quickActions";
Vue.component("qa-orders", QuickActions);

import details from "./areas/details";
Vue.component("orderDetails", details);
import orderLines from "./areas/orderLines";
Vue.component("orderLines", orderLines);
import orderRelatedContact from "./areas/relatedContact";
Vue.component("orderRelatedContact", orderRelatedContact);
import orderRelatedOrganization from "./areas/relatedOrganization";
Vue.component("orderRelatedOrganization", orderRelatedOrganization);
import relatedOrders from "./areas/relatedOrders";
Vue.component("relatedOrders", relatedOrders);

import Orders from "./orders";
import Order from "./order";

routes.push({ path: "orders", component: RouterView, meta: { label: "Ordrer" }, children: [
    { path: "", component: Orders, name: "orders", meta: { module: "orders" } },
    { path: ":id", component: Order, name: "order", meta: { label: "Ordre", module: "orders" } }
] });

import store from './store';

export { routes, store };
