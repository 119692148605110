<template>
    <div>
        <b-card-body v-if="entity.orderLines">

            <b-table stacked="md" class="orderlines-table" striped  :items="entity.orderLines" :fields="columns">
                
                <template v-slot:cell(product)="data">
                    <b-dropdown boundary=".orderlines-table" class="products-dropdown" no-caret toggle-tag="div" :ref="'dropdown' + data.index" @shown="ddShow(data)" @hide="ddHide(data, $event)">
                        <template slot="button-content">
                            <b-form-input :ref="'dd-product' + data.index" @keyup="getProducts(data.item.product)" @focus="ddShow(data, $event)" type="text" v-model="data.item.product" autocomplete="off"></b-form-input>
                        </template>
                        <b-dropdown-text>
                            <table class="table b-table-stacked-lg">
                                <tr v-for="product in products" :key="product.id">
                                    <td class="text-nowrap">{{product.name}}</td>
                                    <td style="min-width: 160px">
                                        <b-form-select v-model="product.selectedVariant" :options="product.variants" v-if="product.variants && product.variants.length > 0"
										    text-field="5ce2e8e046cd0030dcf950e0" value-field="id" class=""></b-form-select>
                                    </td>
                                    <td class="text-nowrap" v-if="product.variants && product.variants.find(x => x.id == product.selectedVariant) && product.variants.find(x => x.id == product.selectedVariant)['5ce2e90346cd0030dcf950e3']">
                                        {{$numeral(product.variants.find(x => x.id == product.selectedVariant)['5ce2e90346cd0030dcf950e3']).format("#,#.00")}} DKK
                                    </td>
                                    <td v-else class="text-nowrap">{{$numeral(product.price).format("#,#.00")}} DKK</td>
                                    <td><b-button @click="selectProduct(product, data)" variant="primary" size="sm"><i class="fa fa-check"></i></b-button></td>
                                </tr>
                            </table>
                        </b-dropdown-text>
                        <b-dropdown-item><i class="fa fa-plus"></i> Opret nyt produkt</b-dropdown-item>
                    </b-dropdown>
                    <span v-if="data.item.productId" @click="removeSelectedProduct(data.item, $event)" class="fa fa-times fa-lg text-danger"></span>
                </template>

                <template v-slot:cell(quantity)="data">
                    <b-form-input class="pr-1" type="number" v-model="data.item.quantity" @input="updatePrice(data.item)" autocomplete="off"></b-form-input>
                </template>

                <template v-slot:cell(price)="data">
                    <b-form-input type="text" v-model="data.item.price" @change="updatePrice(data.item)" autocomplete="off"></b-form-input>
                </template>

                <template v-slot:cell(priceWithVat)="data">
                    <b-form-input type="text" v-model="data.item.priceWithVat" @change="updatePriceWithVat(data.item)" autocomplete="off"></b-form-input>
                </template>

                <template v-slot:cell(discount)="data">
                    <b-form-input type="text" v-model="data.item.discount" @input="updatePrice(data.item)" autocomplete="off"></b-form-input>
                </template>

                <template v-slot:cell(totalPrice)="data">
                    <span class="text-nowrap">{{$numeral(data.item.totalPrice).format("#,#.00")}} DKK</span>
                </template>

                <template v-slot:cell(edit)="data">
                    <b-button @click="toggleDetails(data)" variant="primary"><i class="fa fa-ellipsis-h"></i></b-button>

                    <b-button @click="removeOrderline(data.index)" variant="danger"><i class="fa fa-trash"></i></b-button>
                </template>

                <template v-slot:row-details="data">
                    <b-card>
                        <div class="row">
                            <div class="col-md-6 col-lg-3">
                                <FormGroup label="Variant">
                                    <b-form-input type="text" v-model="data.item.variant" autocomplete="off" 
                                        :disabled="data.item.productId != null"></b-form-input>
                                </FormGroup>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FormGroup label="Vægt">
                                    <b-form-input type="text" :value="data.item.weight" :disabled="data.item.productId != null"
                                    @input="x => data.item.weight = $numeral(x).value()" autocomplete="off"></b-form-input>
                                </FormGroup>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FormGroup label="SKU">
                                    <b-form-input type="text" v-model="data.item.sku" autocomplete="off"
                                        :disabled="data.item.productId != null"></b-form-input>
                                </FormGroup>
                            </div>
                            <div class="col-md-6 col-lg-3">
                                <FormGroup label="Producent">
                                    <b-form-input type="text" v-model="data.item.brand" autocomplete="off"
                                        :disabled="data.item.productId != null"></b-form-input>
                                </FormGroup>
                            </div>
                        </div>
                        <div class="row" v-if="productSpec">
                            <div class="col-md-6 col-lg-4" v-for="field in productSpec.fields" :key="field.id">
                                <component :is="'editor-' + field['5d13b746f7cb1732200f1c5e']" :entity="data.item.specs" 
                                    :label="field['5d13b72af7cb1732200f1c5d']" scope="module" :alias="field.id" @update="specPriceUpdate($event, data.item)"
                                    :validate="false" :values="field['5d13b7cbf7cb1732200f1c60']" :default="field['5d13b776f7cb1732200f1c5f']"></component>
                            </div>
                        </div>
                    </b-card>
                </template>
            </b-table>

            <div class="total-container">
                <div class="total">
                    <div>
                        <div class="float-left">Subtotal</div>
                        <div class="float-right">{{$numeral(totals.subTotal).format("0,0.00 $")}}</div>
                        <div class="clearfix"></div>
                    </div>
                    <div>
                        <div class="float-left">Moms (25,00%)</div>
                        <div class="float-right">{{$numeral(totals.vat).format("0,0.00 $")}}</div>
                        <div class="clearfix"></div>
                    </div>
                    <div v-if="totals.discount > 0">
                        <div class="float-left">Rabat</div>
                        <div class="float-right">-{{$numeral(totals.discount).format("0,0.00 $")}}</div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="last">
                        <div class="float-left">Total DKK</div>
                        <div class="float-right">{{$numeral(totals.total).format("0,0.00 $")}}</div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

        </b-card-body>
        <b-card-footer>
            <b-button @click="addOrderLine" size="sm" variant="primary"><span class="fa fa-plus pr-1"></span> Tilføj produkt</b-button>
        </b-card-footer>
    </div>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/dynamicEditors";

export default {
    data: () => ({
        products: [],
        productSpec: null,
        columns: [
            { label: 'Produkt', key: 'product', sortable: false, class: 'px-1 py-2', tdAttr: {  } },
            { label: 'Antal', key: 'quantity', sortable: false, class: 'px-1 py-2', tdAttr: { style: "width: 80px;" }  },
            { label: 'Pris u. moms', key: 'price', sortable: false, class: 'px-1 py-2', tdAttr: { style: "width: 110px;" } },
            { label: 'Pris m. moms', key: 'priceWithVat', sortable: false, class: 'px-1 py-2', tdAttr: { style: "width: 110px;" } },
            { label: 'Rabat m. moms', key: 'discount', sortable: false, class: 'px-1 py-2', tdAttr: { style: "width: 110px;" } },
            { label: 'Total pris', key: 'totalPrice', sortable: false, class: 'px-1 py-2', tdAttr: { style: "width: 90px;" } },
            { label: '', key: 'edit', sortable: false, class: 'px-1 py-2 text-right', tdAttr: { style: "width: 90px;" } }
        ],
        productSpecs: []
    }),
    watch: {
        totals(val) {
            this.entity.subtotal = val.subTotal;
            this.entity.discount = val.discount;
            this.entity.vat = val.vat;
            this.entity.total = val.total;
        },
        "entity.orderLines": {
            handler() {                
                this.getOrderSpecs();
            },
            deep: true
        }
    },
    components: { 
		...Editors
	},
    computed: {
		creating() {
			return this.$route.params.id == "create";
		},
		id() {
			return this.$route.params.id;
        },
        totals() {
            var subTotal = 0;
            var vat = 0;
            var discount = 0;
            var total = 0;

            if (this.entity.orderLines) {
                this.entity.orderLines.forEach(x => {
                    var price = this.$numeral(x.price).value() * x.quantity;
                    var totalPrice = this.$numeral(x.totalPrice).value();

                    var priceVat = totalPrice * 0.8;
                    subTotal += priceVat;
                    vat += (totalPrice * 0.2);
                    discount += this.$numeral(x.discount).value();
                });
            }

            total = (subTotal + vat);

            return {
                subTotal: subTotal,
                discount: discount,
                vat: vat,
                total: total
            };
        }
    },
    methods: {
        getOrderSpecs() {
            if (this.entity.orderLines) {
                var productIds = this.entity.orderLines.filter(x => x.productId != null).map(x => x.productId);
                this.$http.post("/api/module/orders/productspecs", productIds).then(x => {
                    this.productSpecs = x.data;
                });
            }
        },
        addOrderLine() {
            this.entity.orderLines.push({
                product: null,
                productId: null,
                variantId: null,
                sku: null,
                quantity: 1,
                price: null,
                priceWithVat: null,
                discount: 0,
                totalPrice: null,
                weight: 0,
                specs: {}
            });
        },
        removeOrderline(idx) {
            if (confirm("Er du sikker på at du vil fjerne denne ordrelinje?")) {
                this.entity.orderLines.splice(idx, 1);
            }
        },

        ddShow(x, e) {
            this.$refs['dd-product' + x.index].focus();            
            if (x.item.product) {
                this.getProducts(x.item.product);
            }

            this.$nextTick();
        },
        ddHide(x, e) {
            if (document.activeElement.id == this.$refs['dd-product' + x.index].$el.id) {
                e.preventDefault();
            } else {
                this.products = [];
            }
        },
        getProductFilter() {

        },
        getProducts(value) {
            var filterField = this.module.settings.find(x => x.alias == "productFilterField");
            var filterOrderField = this.module.settings.find(x => x.alias == "productFilterOrderField");

            var filter = null;

            if (filterField && filterOrderField) {
                filter = {
                    field: filterField.value,
                    value: this.entity[filterOrderField.value]
                }
            }

            this.$http.post("/api/module/orders/products", filter, {
                params: {
                    search: value
                }
            }).then(x => {
                this.products = x.data;
                this.products.forEach(x => {
                    if (x.variants && x.variants.length > 0) {
                        this.$set(x, "selectedVariant", x.variants[0].id)
                    }
                })
            });
        },

        selectProduct(product, data) {
            var item = data.item;
            item.product = product.name;
            item.productId = product.Id;
            item.variantId = product.selectedVariant;
            item.sku = product.sku;
            item.quantity = 1;
            item.weight = product.weight;
            item.brand = product.brand;
            if (product.price) {
                item.price = this.$numeral(product.price).format("#.#");
            } else {
                item.price = 0;
            }
            if (product.selectedVariant) {
                var variant = product.variants.find(x => x.id == product.selectedVariant);
                item.variant = variant["5ce2e8e046cd0030dcf950e0"];
                item.sku = variant["5ce2e8ef46cd0030dcf950e1"];
                if (variant["5ce2e90346cd0030dcf950e3"]) {
                    item.price = this.$numeral(variant["5ce2e90346cd0030dcf950e3"]).format("#.#");
                }
            }
            item.discount = 0;
            item.priceWithVat = this.$numeral(this.$numeral(item.price).value() * 1.25).format("#.#");
            item.totalPrice = item.priceWithVat;

            this.$refs['dropdown' + data.index].hide();

            this.getOrderSpecs();
        },
        removeSelectedProduct(item, e) {
            e.stopPropagation();

            item.productId = null;
            item.variantId = null;
            item.variant = null;
            item.sku = null;

            this.$set(item, "_showDetails", false);
        },
        updatePrice(item) {
            var quantity = item.quantity;
            var price = this.$numeral(item.price).value();

            var priceWithVat = price * 1.25;
            var discount = this.$numeral(item.discount).value();

            var specs = item.specs;
            var specPrice = 0;
            if (item.productId) {
                var productSpecs = this.productSpecs.find(x => x.Id == item.productId);
                if (productSpecs) {
                    var fields = productSpecs.fields.filter(x => x["5d13b746f7cb1732200f1c5e"] == "calculation");


                    fields.forEach(x => {
                        var fieldId = x.id;
                        var calcField = specs[fieldId];
                        var calcValue = this.$numeral(calcField).value();

                        specPrice += calcValue;
                    });
                }
            }

            var totalPrice = (priceWithVat + specPrice - discount) * quantity;

            item.price = this.$numeral(price).format("#.#");
            item.priceWithVat = this.$numeral(priceWithVat).format("#.#");
            item.totalPrice = this.$numeral(totalPrice).format("#.#");
        },
        updatePriceWithVat(item) {
            var priceWithVat = this.$numeral(item.priceWithVat).value();
            var price = priceWithVat * 0.8;

            item.price = this.$numeral(price).format("#.#");

            this.updatePrice(item);
        },

        toggleDetails(data) {            
            this.entity.orderLines.forEach((x,i) => {
                if (i != data.index) {
                    this.$set(x, "_showDetails", false);
                }
            });
            
            if (data.item.productId) {
                this.productSpec = this.productSpecs.find(x => x.Id == data.item.productId);
            } else {
                this.productSpec = null;
            }
            data.toggleDetails();
        },
        specPriceUpdate(val, line) {
            this.updatePrice(line);
        },
    },
    created() {
        if (!this.entity.orderLines) {
            this.$set(this.entity, "orderLines", []);
        }

        this.getOrderSpecs();
    },
    mounted() {},
    mixins: [mixin]
};
</script>