<template>
    <b-card-body class="" v-if="entity">

        <b-row>
            <b-col>
                <b-card>
                    <template #header>
                        <div class="d-flex justify-content-between align-items-center">
                            {{entity.name}} #{{entity.sku}} 

                            <div>
                                <b-button :disabled="loading" class="font-weight-bold mr-3" variant="success" @click="saveProduct">
                                    <b-spinner v-if="loading" small label="Indlæser"></b-spinner>
                                    Gem produkt
                                </b-button>

                                <b-button :disabled="loading" class="font-weight-bold mr-3" variant="danger" @click="updateShop(true)">
                                    <b-spinner v-if="loading" small label="Indlæser"></b-spinner>
                                    Opdater webshop
                                </b-button>

                                <b-button :disabled="loading" class="font-weight-bold" variant="warning" @click="updateShop(false)">
                                    <b-spinner v-if="loading" small label="Indlæser"></b-spinner>
                                    Opdater shop tekster
                                </b-button>
                            </div>
                        </div>
                    </template>

                    <div class="row">
                        <div class="col col-lg-7">
                            <div class="row">
                                <div class="col col-lg-3">
                                    <EditorSelect :values="['Ja', 'Nej']" :entity="entity" label="Prisstyring" alias="pricing" :validate="false" scope="module"></EditorSelect>
                                </div>

                                <div class="col col-lg-3">
                                    <FormGroup label="Varianttype" scope="module">
                                        <OptifySelect :options="types" v-model="entity.variantType" label="title" track-by="id" autocomplete="off"></OptifySelect>
                                    </FormGroup>
                                </div>

                                <div class="col col-lg-3">
                                    <EditorSelect :values="['Højest', 'Middel', 'Lavest']" :entity="entity" label="Beregnet vægt" alias="weightValue" :validate="false" scope="module"></EditorSelect>
                                </div>

                                <div class="col col-lg-3">
                                    <EditorSelect :values="['Højest', 'Middel', 'Lavest']" :entity="entity" label="Vist vægt på shoppen" alias="weightDisplay" :validate="false" scope="module"></EditorSelect>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col col-lg-5">
                            <div class="row">
                                <div class="col col-lg-6">
                                    <EditorRichText :entity="entity" label="Intern note 1" alias="internNote1" :validate="false" scope="module"></EditorRichText>
                                </div>

                                <div class="col col-lg-6">
                                    <EditorRichText :entity="entity" label="Intern note 2" alias="internNote2" :validate="false" scope="module"></EditorRichText>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="row mt-3">
                        <div class="col col-lg-6">
                            <div class="d-flex mb-2" style="gap: 10px;">
                                <EditorNumber class="w-100" :entity="entity" label="Kostpris pr. kg. u/moms" alias="costKG" :validate="false" scope="module"></EditorNumber>

                                <EditorNumber class="w-100" :entity="entity" label="Salgspris pr. kg. m/moms" alias="saleKG" :validate="false" scope="module"></EditorNumber>

                                <EditorNumber class="w-100" :entity="entity" label="Rabat pr. kg. m/moms" alias="discountKG" :validate="false" scope="module"></EditorNumber>
                            </div>

                            <div class="d-flex" style="gap: 10px;">
                                <EditorSelect class="w-100" :values="['Ja', 'Nej']" :entity="entity" label="Opdater tekstfelter" alias="updateText" :validate="false" scope="module"></EditorSelect>

                                <div class="w-100">
                                    <FormGroup label="Upload icon">
                                        <b-form-file @change="setIconUpload" ref="iconUpload" name="iconUpload" autocomplete="off"></b-form-file>
                                    </FormGroup>
                                    <div v-if="entity.iconUpload" class="">
                                        <img :src="entity.iconUpload" width="50" class="mr-2" />
                                        <span @click="entity.iconUpload = null" class="fa fa-trash text-danger" style="cursor: pointer;"></span>
                                    </div>
                                </div>

                                <EditorSelect class="w-100" :values="['Udregn halv-kg. pris', 'Udregn hel-kg. pris']" :entity="entity" label="Flerstyk kg. udregning" alias="discountKgPriceCalc" :validate="false" scope="module"></EditorSelect>
                            </div>
                       
                        </div>

                        <div class="col col-lg-6">
                            <div class="font-weight-bold d-flex justify-content-between mb-1">
                                Flerstyk rabat

                                <b-button variant="primary" size="sm" @click="addAmountDiscount">Tilføj</b-button>
                            </div>

                            <div class="d-flex align-items-center mb-1" v-for="(item, key) in entity.amountDiscounts" :key="key" style="gap: 10px;">
                                <div class="d-flex align-items-center">
                                    <span class="text-nowrap mr-2">Antal</span>
                                    <input class="form-control" type="number" v-model="item.amount" />
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="text-nowrap mr-2">Pris kg.</span>
                                    <input class="form-control" type="number" v-model="item.price" @change="calcAmountDiscount(item)" />
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="text-nowrap mr-2">Fra dato</span>
                                    <input class="form-control" type="datetime-local" step="1800" v-model="item.startDate" />
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="text-nowrap mr-2">Til dato</span>
                                    <input class="form-control" type="datetime-local" step="1800" v-model="item.endDate" />
                                </div>
                                <div class="d-flex align-items-center pr-2 font-weight-bold">
                                    {{ $numeral(item.discount).format("0.00") }}%

                                    <a href="#" @click.prevent="entity.amountDiscounts.splice(key, 1)" class="text-danger ml-2">
                                        <i class="fa fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>

            </b-col>

        </b-row>

        <b-row>
            <b-col>

                <b-card>
                    <template #header>
                        <div class="d-flex justify-content-between align-items-center">
                            Varianter

                            <div class="">
                                <b-button :disabled="loading" class="mr-1" variant="success" @click="createVariants">
                                    <b-spinner v-if="loading" small label="Indlæser"></b-spinner>
                                    Opret varianter
                                </b-button>
                                <b-button :disabled="loading" variant="danger" @click="deleteVariants">
                                    <b-spinner v-if="loading" small label="Indlæser"></b-spinner>
                                    Slet varianter
                                </b-button>
                            </div>
                        </div>
                    </template>

                    <div>

                        <table class="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>Navn</th>
                                    <th>Vægt grundlag</th>
                                    <th>Vægt</th>
                                    <th>Kostpris stk. u/moms</th>
                                    <th>Pris stk. m/moms</th>
                                    <th>Rabat stk. m/moms</th>
                                    <th>Salgspris</th>
                                    <th>Fortjeneste u/moms</th>

                                    <th class="" v-for="(x,i) in entity.amountDiscounts" :key="i">
                                        {{x.amount}} stk. <i v-if="x.startDate || x.endDate" class="fa fa-calendar-alt text-danger"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in variants" :key="item.id">
                                    <td>{{item.sku}}</td>
                                    <td v-html="item.title"></td>
                                    <td>{{item.weightCalc}} gr.</td>
                                    <td>{{item.weight}} gr.</td>
                                    <td>{{item.cost}} kr.</td>
                                    <td>{{item.price}} kr.</td>
                                    <td>{{item.discount}} kr.</td>
                                    <td>{{item.salePrice}} kr.</td>
                                    <td class="font-weight-bold">{{item.revenue}} kr. ({{$numeral(item.revenuePercent).format('0.0')}}%)</td>
                                    <td class="" v-for="(x,i) in entity.amountDiscounts" :key="i">
                                        {{ calcDiscountRevenue(item, x) }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </b-card>
            </b-col>
        </b-row>

    </b-card-body>
</template>

<script>
    import mixin from "../mixins/module";
    import Editors from "../mixins/editors";

    export default {
        data: () => ({
            entity: null,
            types: [],
            loading: false
        }),
        components: { ...Editors },
        watch: {
            id() {
                this.getEntity();
            },
            "entity.saleKG"() {
                this.calcAmountDiscounts()
            },
            "entity.discountKG"() {
                this.calcAmountDiscounts()
            }
        },
        computed: {
            id() {
                return this.$route.params.id;
            },

            variants() {
                return this.entity.variants.map(x => {
                    var weight = this.$numeral(x['weightCalc']).value() / 1000

                    var cost = Math.ceil((this.entity.costKG || 0) * weight)
                    var price = Math.ceil((this.entity.saleKG || 0) * weight)
                    var discount = Math.ceil((this.entity.discountKG || 0) * weight)
                    var salePrice = Math.ceil(price - discount)
                    var revenue = Math.ceil((salePrice * 0.8) - cost)
                    var revenuePercent = revenue / (salePrice * 0.8) * 100

                    return {
                        id: x.id,
                        sku: x['5ce2e8ef46cd0030dcf950e1'],
                        title: x['5ce2e8e046cd0030dcf950e0'],
                        weightCalc: x['weightCalc'],
                        weight: x['5dd7fa2b52b45f2c74a31575'],
                        price: price,
                        salePrice: salePrice,
                        cost: cost,
                        discount: discount,
                        revenue: revenue,
                        revenuePercent: revenuePercent
                    }
                })
            }
        },
        methods: {
            getEntity() {
                this.$http
                    .get("/api/module/pricing/product/" + this.id)
                    .then((x) => {
                        this.entity = x.data;
                        this.resetValidation();
                    });
            },

            getVariantTypes() {
                this.$http.get("/api/module/pricing/variant-types/all").then(x => {{
                    this.types = x.data.map(x => ({ ...x, title: this.decodeHtml(x.title) }))
                }})
            },

            saveProduct() {
                this.loading = true
                this.$http
                    .post("/api/module/pricing/product/" + this.id, this.entity)
                    .then((x) => {
                        if (x.data === false) {
                            this.showErrorMsg();
                        } else {
                            this.bus.$emit("submit");
                            this.showSuccessMsg();

                            this.getEntity();
                        }

                        this.loading = false
                    });
            },

            addAmountDiscount() {
                if (this.entity.amountDiscounts == null) {
                    this.$set(this.entity, "amountDiscounts", [])
                }

                this.entity.amountDiscounts.push({
                    amount: 1,
                    price: 0,
                    discount: 0
                })
            },
            calcAmountDiscounts() {
                if (!this.entity.amountDiscounts) {
                    this.entity.amountDiscounts = []
                }
                this.entity.amountDiscounts.forEach(x => {
                    this.calcAmountDiscount(x)
                })            },
            calcAmountDiscount(item) {
                if (!item.price) {
                    item.price = 0
                }
                item.discount = (1 - (item.price / (this.entity.saleKG - this.entity.discountKG))) * 100
            },
            calcDiscountRevenue(variant, discount) {
                var price = this.$numeral(variant.salePrice).subtract(variant.salePrice * (discount.discount / 100)).value()
                var priceText = this.$numeral(price).format('0')

                var revenue = Math.ceil((price * 0.8) - variant.cost)
                var revenuePercent = revenue / (price * 0.8) * 100

                return priceText + "kr. (" + this.$numeral(revenuePercent).format('0.0') + "%)"
            },


            deleteVariants() {
                if (confirm("Er du sikker på at du vil slette alle varianter?")) {
                    this.loading = true

                    this.$http
                        .delete("/api/module/pricing/product/" + this.id + "/variants?productId=" + this.entity.productId)
                        .then((x) => {
                            this.getEntity();

                            this.loading = false
                        });
                }
            },

            createVariants() {
                this.loading = true

                this.$http
                    .post("/api/module/pricing/product/" + this.id + "/variants", this.entity)
                    .then((x) => {
                        this.getEntity();

                        this.loading = false
                    });
            },

            updateShop(includeVariants) {
                if (confirm("Er du sikker på at du vil opdatere produktets priser i shoppen?")) {
                    this.loading = true

                    this.$http
                        .post("/api/module/pricing/product/" + this.id + "/update-shop?includeVariants=" + includeVariants, this.entity)
                        .then((x) => {
                            this.getEntity();

                            this.loading = false
                        });
                }
            },

            setIconUpload(e) {
                var file = e.target.files[0]

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.$set(this.entity, "iconUpload", reader.result)
                }
                reader.onerror = () => {
                    this.$set(this.entity, "iconUpload", null)
                }

                e.target.value = null
            },

            decodeHtml(input) {
                var list = [
                    ['\"', /&quot;/g],
                    ['\'', /&apos;/g],
                    ['\'', /&#039;/g],
                    ['&', /&amp;/g],
                    ['<', /&lt;/g],
                    ['>', /&gt;/g],
                    ['¡', /&iexcl;/g],
                    ['¢', /&cent;/g],
                    ['£', /&pound;/g],
                    ['¤', /&curren;/g],
                    ['¥', /&yen;/g],
                    ['¦', /&brvbar;/g],
                    ['§', /&sect;/g],
                    ['¨', /&uml;/g],
                    ['©', /&copy;/g],
                    ['ª', /&ordf;/g],
                    ['«', /&laquo;/g],
                    ['¬', /&not;/g],
                    ['­', /&shy;/g],
                    ['®', /&reg;/g],
                    ['¯', /&macr;/g],
                    ['°', /&deg;/g],
                    ['±', /&plusmn;/g],
                    ['²', /&sup2;/g],
                    ['³', /&sup3;/g],
                    ['´', /&acute;/g],
                    ['µ', /&micro;/g],
                    ['¶', /&para;/g],
                    ['·', /&middot;/g],
                    ['¸', /&cedil;/g],
                    ['¹', /&sup1;/g],
                    ['º', /&ordm;/g],
                    ['»', /&raquo;/g],
                    ['¼', /&frac14;/g],
                    ['½', /&frac12;/g],
                    ['¾', /&frac34;/g],
                    ['¿', /&iquest;/g],
                    ['×', /&times;/g],
                    ['÷', /&divide;/g],
                    ['À', /&Agrave;/g],
                    ['Á', /&Aacute;/g],
                    ['Â', /&Acirc;/g],
                    ['Ã', /&Atilde;/g],
                    ['Ä', /&Auml;/g],
                    ['Å', /&Aring;/g],
                    ['Æ', /&AElig;/g],
                    ['Ç', /&Ccedil;/g],
                    ['È', /&Egrave;/g],
                    ['É', /&Eacute;/g],
                    ['Ê', /&Ecirc;/g],
                    ['Ë', /&Euml;/g],
                    ['Ì', /&Igrave;/g],
                    ['Í', /&Iacute;/g],
                    ['Î', /&Icirc;/g],
                    ['Ï', /&Iuml;/g],
                    ['Ð', /&ETH;/g],
                    ['Ñ', /&Ntilde;/g],
                    ['Ò', /&Ograve;/g],
                    ['Ó', /&Oacute;/g],
                    ['Ô', /&Ocirc;/g],
                    ['Õ', /&Otilde;/g],
                    ['Ö', /&Ouml;/g],
                    ['Ø', /&Oslash;/g],
                    ['Ù', /&Ugrave;/g],
                    ['Ú', /&Uacute;/g],
                    ['Û', /&Ucirc;/g],
                    ['Ü', /&Uuml;/g],
                    ['Ý', /&Yacute;/g],
                    ['Þ', /&THORN;/g],
                    ['ß', /&szlig;/g],
                    ['à', /&agrave;/g],
                    ['á', /&aacute;/g],
                    ['â', /&acirc;/g],
                    ['ã', /&atilde;/g],
                    ['ä', /&auml;/g],
                    ['å', /&aring;/g],
                    ['æ', /&aelig;/g],
                    ['ç', /&ccedil;/g],
                    ['è', /&egrave;/g],
                    ['é', /&eacute;/g],
                    ['ê', /&ecirc;/g],
                    ['ë', /&euml;/g],
                    ['ì', /&igrave;/g],
                    ['í', /&iacute;/g],
                    ['î', /&icirc;/g],
                    ['ï', /&iuml;/g],
                    ['ð', /&eth;/g],
                    ['ñ', /&ntilde;/g],
                    ['ò', /&ograve;/g],
                    ['ó', /&oacute;/g],
                    ['ô', /&ocirc;/g],
                    ['õ', /&otilde;/g],
                    ['ö', /&ouml;/g],
                    ['ø', /&oslash;/g],
                    ['ù', /&ugrave;/g],
                    ['ú', /&uacute;/g],
                    ['û', /&ucirc;/g],
                    ['ü', /&uuml;/g],
                    ['ý', /&yacute;/g],
                    ['þ', /&thorn;/g],
                    ['ÿ', /&yuml;/g]
                ];
                for(var c = 0; c<list.length; c++){
                    input  = input.replace(list[c][1], list[c][0]);
                }
                return input;
            }
        },
        created() {
            this.getEntity();
            this.getVariantTypes();
        },
        mounted() { },
        mixins: [mixin]
    };
</script>