<template>
    <router-view></router-view>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {},
	created() {},
	mounted() {}
};
</script>