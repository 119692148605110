<template>
    <b-card-body>

        <TextEditor :entity="entity" label="Navn" alias="name" :validate="true" scope="module"></TextEditor>

        <TextEditor :entity="entity" label="Beskrivelse" alias="description" :validate="false" scope="module"></TextEditor>

        <TextEditor :entity="entity" label="SKU" alias="sku" :validate="false" scope="module"></TextEditor>

        <TextEditor :entity="entity" label="GTIN" alias="gtin" :validate="false" scope="module"></TextEditor>

        <NumberEditor :entity="entity" label="Pris" alias="price" :validate="false" scope="module"></NumberEditor>

        <NumberEditor :entity="entity" label="Kostpris" alias="costPrice" :validate="false" scope="module"></NumberEditor>

        <NumberEditor :entity="entity" label="Vægt" alias="weight" :validate="false" scope="module"></NumberEditor>

        <NumberEditor :entity="entity" label="Lagerantal" alias="stock" :validate="false" scope="module"></NumberEditor>

        <TextEditor :entity="entity" label="Producent" alias="brand" :validate="false" scope="module"></TextEditor>



        <FormGroup label="Varegruppe" alias="productGroup" label-cols-md="3" v-slot:default="props" scope="module">
            <OptifySelect :options="productGroups" v-model="entity.productGroup" :multiple="false" :show-labels="true"
                          @search="searchProductGroups" @init="searchProductGroups" label="text" track-by="id" :internal-search="false" autocomplete="off">
            </OptifySelect>
        </FormGroup>

        <FormGroup label="Specifikation" alias="productSpec" label-cols-md="3" v-slot:default="props" scope="module">
            <OptifySelect id="productSpec" :options="productSpecs" v-model="entity.productSpec" :multiple="false" :show-labels="true"
                          @search="searchProductSpecs" @init="searchProductSpecs" label="text" track-by="id" :internal-search="false" autocomplete="off">
            </OptifySelect>
        </FormGroup>

    </b-card-body>
</template>

<script>
    import mixin from "../../mixins/area";
    import TextEditor from "../../_ui/editors/text";
    import NumberEditor from "../../_ui/editors/number";

    export default {
        data: () => ({
            productGroups: [],
            productSpecs: []
        }),
        components: { TextEditor, NumberEditor },
        computed: {},
        methods: {
            searchProductGroups(query, value) {
                this.$http.post("/api/multi-select/products", {
                    search: query,
                    value: value,
                    values: [],
                    collection: "productgroups",
                    fields: ["5ce68ef5d6c2910bb09c58a5"]
                }).then(x => {
                    this.productGroups = x.data;
                });
            },
            searchProductSpecs(query, value) {
                this.$http.post("/api/multi-select/products", {
                    search: query,
                    value: value,
                    values: [],
                    collection: "productSpecs",
                    fields: ["5d139c4a0512d063ecf457fe"]
                }).then(x => {
                    this.productSpecs = x.data;
                });
            }
        },
        created() {
        },
        mounted() {
        },
        mixins: [mixin]
    };
</script>